<template>
  <div class="app-tooltip">
    <slot name="content"></slot>
    <span
      v-bind:class="[
        { 'app-tooltiptext': true, 'app-tooltip-colors-inverted': this.colorsInverted },
        positionClass,
        widthClass,
        customClass,
      ]"
    >
      <slot name="tooltip"></slot>
    </span>
  </div>
</template>

<script>
export default {
  props: ["colorsInverted", "position", "width", "customClass"],
  computed: {
    positionClass() {
      switch (this.position) {
        case "top":
          return "app-tooltip-position-top";
        case "bottom":
          return "app-tooltip-position-bottom";
        case "left":
          return "app-tooltip-position-left";
        default:
          return "app-tooltip-position-bottomright";
      }
    },
    widthClass() {
      switch (this.width) {
        case "medium":
          return "app-tooltip-width-medium";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.app-tooltip {
  position: relative;
  display: inline-block;
}

.app-tooltiptext.app-tooltip-colors-inverted {
  color: #fff;
  background-color: var(--knittable-opal-dark);
}

.app-tooltiptext {
  color: var(--knittable-opal-dark);
  background-color:(--knittable-isa);
}

.app-tooltip .app-tooltiptext {
  visibility: hidden;
  text-align: center;
  border-radius: 6px;
  padding: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  margin-left: -70px;
}

.app-tooltip-position-bottomright {
  top: 80%;
  left: 90%;
}

.app-tooltip-position-top {
  bottom: 100%;
  left: 50%;
}

.app-tooltip-position-bottom {
  top: 100%;
  left: 50%;
}

.app-tooltip-position-left {
  top: 50%;
  left: 0%;
  transform: translate(-50%, 7%); 
}

.app-tooltip-width-medium {
  width: 350px;
}

.app-sm-and-down .app-tooltip-width-medium {
  width: 250px;
}

.app-tooltip:hover .app-tooltiptext {
  visibility: visible;
}
</style>
