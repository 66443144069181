<template>
  <span class="beta-badge">
    <slot>beta</slot>
  </span>
</template>

<style scoped lang="scss">
@import "../../scss/styles.scss";
.beta-badge {
  @include paragraphSmallBold;

  display: inline-block;
  background-color: var(--knittable-begonia);
  color: var(--knittable-isa);
  padding: 3px 6px;
  border-radius: 4px;
  margin-left: 8px;
  text-transform: uppercase;
}
</style>
