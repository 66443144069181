<template>
  <v-footer class="app-footer text-center d-flex flex-column">
    <div>
      <v-btn
        v-for="icon in icons"
        :key="icon.icon"
        class="mx-2"
        :icon="icon.icon"
        variant="text"
        :href="icon.url"
      ></v-btn>
    </div>

    <h4 class="pt-0 pb-5">
      {{ $t("footer.Slogan") }}
    </h4>
    <div class="pt-0 pb-5">
      {{ $t("footer.Knitting_calculations_can_be_time_consuming_") }}
    </div>
    <div>
      {{ $t("footer.Supported_by_") }}
    </div>
    <div>
      <a href="https://www.rannis.is/sjodir/rannsoknir/taeknithrounarsjodur/">
        <img class="app-supporter-logo" src="./../assets/taeknithrounarsjodur_logo_fitted.png" />
      </a>
    </div>

    <v-divider></v-divider>

    <div>{{ new Date().getFullYear() }} — <strong>Knittable</strong></div>
  </v-footer>
</template>

<script setup>
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const icons = [
  { icon: "mdi-linkedin", url: "https://www.linkedin.com/company/knittable" },
  { icon: "mdi-facebook", url: "https://www.facebook.com/profile.php?id=61566610493740" },
  { icon: "mdi-instagram", url: "https://www.instagram.com/itsknittable/" },
  { icon: "mdi-email", url: "mailto:hello@knittable.com" },
];
</script>

<style>
.app-supporter-logo {
  width: 120px;
}

.v-application footer.app-footer {
  background-color: var(--knittable-lav);
  color: white;
}
</style>
