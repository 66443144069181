import axios from "../axios";

import { useAuthStore } from "../stores/auth";

export const saveCurrentSettings = async (toolType, toolVersion, formData) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  const params = {
    tool_version: toolVersion,
    settings: formData,
  };

  await axios.post(`toolsettings/${toolType}/current`, params, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

  return;
};

export const loadCurrentSettings = async (toolType, toolVersion) => {
  try {
    const authStore = useAuthStore();
    const authToken = authStore.authToken;

    const params = {
      tool_version: toolVersion,
    };
    const response = await axios.get(`toolsettings/${toolType}/current`, {
      params: params,
      headers: { Authorization: `Bearer ${authToken}` },
    });

    return response.data.settings;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    } else {
      throw error;
    }
  }
};
