<template>
  <header class="app-pattern-form-header">
    <div>
      {{ $t("common_tools.Calculate_for") }}
    </div>
  </header>
  <toggle-field :options="stitchesOrRowsOptions" v-model="chosenOption"></toggle-field>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";

import ToggleField from "../general/ToggleField.vue";

const { t } = useI18n();

const emit = defineEmits(["update:useStitches"]);

const props = defineProps({
  useStitches: Boolean,
});

const stitchesOrRowsOptions = computed(() => {
  return [
    { key: t("knit_calculator.stitches"), value: true },
    { key: t("knit_calculator.rows"), value: false },
  ];
});

const getOptionFromValue = (value) => {
  return value ? stitchesOrRowsOptions.value[0] : stitchesOrRowsOptions.value[1];
};

const chosenOption = ref(getOptionFromValue(props.useStitches));

watch(
  () => props.useStitches,
  (value) => {
    chosenOption.value = getOptionFromValue(value);
  }
);

watch(chosenOption, (value) => {
  emit("update:useStitches", value.value);
});
</script>

<style lang="scss" scoped>
@import "./../../../scss/form.scss";
</style>
