import mixpanel from "mixpanel-browser";

import { anonymiseUser } from "../lib/userTrackingUtils";

const destinationInterface = {
  make: function (env, apiKey) {
    // Note: Initializing your destination in the make method is optional, you can skip it if you've already initialized your destination
    mixpanel.init(apiKey, { opt_out_tracking_by_default: true });
  },

  logEvent: function (eventName, eventProperties) {
    mixpanel.track(eventName, eventProperties);
  },

  setUserProperties: function (userId, userProperties) {
    mixpanel.people.set(userProperties);
  },

  identify: function (userId) {
    mixpanel.identify(anonymiseUser(userId));
  },

  unidentify: function () {
    mixpanel.reset();
  },

  revenue: function (amount, eventProperties) {
    mixpanel.people.track_charge(amount, eventProperties);
  },

  page: function (pageName, eventProperties) {
    mixpanel.track_pageview({ page: pageName });
  },

  // The following methods are used for group analytics and are not required
  setGroupProperties(groupType, groupId, groupProperties) {
    mixpanel.get_group(groupType, groupId).set(groupProperties);
  },

  addCurrentUserToGroup: function (groupType, groupId, groupProperties) {
    mixpanel.set_group(groupType, groupId);
    mixpanel.get_group(groupType, groupId).set(groupProperties);
  },

  logEventWithGroups: function (eventName, eventProperties, groupTypesToGroupIds) {
    mixpanel.track_with_groups(eventName, eventProperties, groupTypesToGroupIds);
  },

  optIn: function () {
    mixpanel.opt_in_tracking();
  },

  optOut: function () {
    mixpanel.opt_out_tracking();
  },
};

export default destinationInterface;
