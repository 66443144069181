<template>
  <router-link :to="getTo()" :class="[{ 'underline': underline }]">
    <slot />
  </router-link>
</template>

<script setup>
import { useLangStore } from "./../stores/lang";

import { composeRoute } from "./../lib/navigationUtils.js";

const langStore = useLangStore();
const locale = langStore.getLocale();

const props = defineProps({
  to: {
    type: String,
    required: true,
  },
  next: {
    type: String,
  },
  underline: {
    type: Boolean,
    default: true,
  },
});

const getTo = () => {
  return composeRoute(locale.value, props.to, props.next);
};
</script>

<style scoped>
.underline {
  text-decoration: none;
}
</style>
