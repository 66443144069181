<template>
  <v-app-bar v-if="mdAndUp" class="app-navbar justify-center app-md-and-up">
    <v-col cols="4">
      <div class="app-navbar-logo">
        <localized-link to="home">
          <img class="app-logo-image" alt="logo" src="./../assets/knittable-white.svg" />
        </localized-link>
      </div>
    </v-col>
    <v-col no-gutters cols="4" class="app-navbar-views mx-auto">
      <v-btn>
        {{ $t("navbar.Tools") }}
        <v-menu activator="parent" open-on-hover>
          <v-list>
            <v-list-item v-for="(tool, i) in tools" :key="i">
              <localized-link :to="tool.routeName">
                <v-list-item-title>
                  <v-btn variant="flat">{{ tool.title }}</v-btn>
                </v-list-item-title>
              </localized-link>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>
      <v-btn>
        {{ $t("navbar.Patterns") }}
        <v-menu activator="parent" open-on-hover>
          <v-list>
            <v-list-item v-for="(pattern, i) in patterns" :key="i">
              <localized-link :to="pattern.routeName">
                <v-list-item-title>
                  <v-btn variant="flat"
                    >{{ pattern.title }}
                    <div class="beta-badge-container" v-if="pattern.routeName == 'sweater'">
                      <beta-badge></beta-badge></div
                  ></v-btn>
                </v-list-item-title>
              </localized-link>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>
      <v-btn v-if="authToken != null && !isSubscribedOrPartner">
        <localized-link to="subscription">
          {{ $t("navbar.Subscribe") }}
        </localized-link>
      </v-btn>
    </v-col>
    <v-col no-gutters cols="4" class="app-navbar-user-options">
      <v-btn v-if="authToken != null" class="app-btn-icon app-navbar-icon-button">
        <localized-link to="profile">
          <v-icon icon="mdi-cog" />
        </localized-link>
      </v-btn>
      <lang-selector />
      <v-btn
        class="app-sign-in-out-btn text-none app-button"
        v-if="authToken == null"
        :to="`/${locale}/signin`"
      >
        {{ $t("navbar.Sign_in") }}
      </v-btn>
      <v-btn class="app-sign-in-out-btn text-none app-button" v-else @click="signOut">{{
        $t("navbar.Sign_out")
      }}</v-btn>
    </v-col>
  </v-app-bar>
  <v-app-bar v-else class="app-navbar justify-center app-sm-and-down">
    <v-col no-gutters cols="6">
      <div class="app-navbar-logo">
        <localized-link to="home">
          <img alt="logo" class="app-logo-image" src="./../assets/knittable-white.svg" />
        </localized-link>
      </div>
    </v-col>
    <v-col offset-y class="app-navbar-hamburger-menu">
      <v-btn v-if="authToken != null" class="app-btn-icon app-navbar-icon-button">
        <localized-link to="profile">
          <v-icon icon="mdi-account" />
        </localized-link>
      </v-btn>
      <lang-selector />
      <v-icon v-if="!mdAndUp" @click="openHamburgerMenu" class="app-btn-icon" icon="mdi-menu" />
    </v-col>
  </v-app-bar>
  <hamburger-menu
    v-model="drawer"
    :tool-list="tools"
    :pattern-list="patterns"
    :is-subscribed="isSubscribedOrPartner"
  />
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useDisplay } from "vuetify";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";

import { composeRoute } from "../lib/navigationUtils";
import { getPartner, getSubscription } from "../services/access";
import HamburgerMenu from "../components/HamburgerMenu.vue";
import LangSelector from "./../components/LangSelector.vue";
import LocalizedLink from "./../components/LocalizedLink.vue";
import { useLangStore } from "./../stores/lang";
import { useAuthStore } from "../stores/auth";
import BetaBadge from "../components/BetaBadge.vue";

const { mdAndUp } = useDisplay();
const drawer = ref(false);

const { t } = useI18n();
const langStore = useLangStore();
const authStore = useAuthStore();

const router = useRouter();

const tools = computed(() => [
  { title: t("common.Knitting_gauge_from_swatch"), routeName: "gaugefromswatch" },
  { title: t("common7.Knit_calculator"), routeName: "knitcalculator" },
  { title: t("common7.Change_gauge"), routeName: "gaugeconverter" },
]);

const patterns = computed(() => [
  { title: t("common.Socks"), routeName: "socks" },
  {
    title: t("common.Mittens"),
    routeName: "mittens",
  },
  { title: t("common.Beanie"), routeName: "beanie" },
  { title: t("common.Sweater"), routeName: "sweater" },
]);

const locale = langStore.getLocale();

const { authToken } = storeToRefs(authStore);
const isSubscribedOrPartner = ref(null);

watch(
  () => authToken.value,
  () => {
    loadSubscriptionInfo();
  }
);

async function signOut() {
  authStore.signOut();

  router.push(composeRoute(locale.value, "signin"));
}

const openHamburgerMenu = () => {
  drawer.value = true;
};

onMounted(() => {
  loadSubscriptionInfo();
});

async function loadSubscriptionInfo() {
  if (!authToken.value) {
    return;
  }

  try {
    const subscription = await getSubscription();
    isSubscribedOrPartner.value = subscription.planId !== null;
    if (isSubscribedOrPartner.value) {
      return;
    }
    const partner = await getPartner();
    isSubscribedOrPartner.value = partner !== null;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      isSubscribedOrPartner.value = false;
    } else {
      alert(error);
    }
  }
}
</script>

<style>
header.v-app-bar.app-navbar {
  background: var(--knittable-lav-darker);
  height: 6.875rem;
  border-radius: 0rem 0rem 2.5rem 2.5rem;
}

.app-navbar-logo {
  position: fixed;
  top: 2.5rem;
  left: 7.5rem;
  height: 1.4375rem;
  text-align: left;
}

.app-md-and-up .app-navbar-logo {
  left: 7.5rem;
}

.app-sm-and-down .app-navbar-logo {
  left: 1.5rem;
}

.app-navbar-views {
  list-style-type: none;
  margin: 0;
  padding: 0;
  .v-btn,
  .v-menu {
    text-transform: unset;
    font-variant-numeric: lining-nums tabular-nums;
    /* font-family: Neue Haas Grotesk Text Pro; */
    font-family: "Neue Haas Grotesk Display Pro", sans-serif;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 550;
    line-height: 2rem;
    letter-spacing: 0.0225rem;
    color: var(--knittable-isa);
  }
}

.v-btn a {
  text-decoration: none;
  color: var(--knittable-isa);
}

.v-menu a {
  text-decoration: none;
}

.app-navbar-user-options {
  color: var(--knittable-isa);
  text-align: right;
}

.v-col.app-navbar-user-options {
  padding-right: 7.5rem;
}

.v-btn.app-sign-in-out-btn {
  width: 8.2rem;

  border-radius: 1rem;
  border: 2px solid var(--knittable-lav-light);
  background: var(--knittable-isa);
  .v-btn__content {
    color: var(--knittable-lav-darker);
  }
}

.v-col.app-navbar-hamburger-menu {
  text-align: right;
  color: var(--knittable-isa);
  padding-right: 2.5rem;
}

.app-logo-image {
  height: 1.4375rem;
}
</style>
