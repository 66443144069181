<template>
  <div v-for="result in props.data">
    <p v-if="props.useStitches" class="app-tool-result">
      {{
        $t("knit_calculator.Stitches_equals_with_pattern", {
          stitchCount: Math.round(result.count) || "?",
          width: result.dimension || "?",
          widthDifference:
            getFormattedWidthDifference(result.dimension, props.wantedDimension) || "?",
          patternRepetition: result.patternRepetition || "?",
          patternAdditionalCount: result.patternAdditionalCount,
        })
      }}
    </p>
    <p v-else class="app-tool-result">
      {{
        $t("knit_calculator.Rows_equals_with_pattern", {
          rowCount: Math.round(result.count) || "?",
          length: result.dimension || "?",
          lengthDifference:
            getFormattedWidthDifference(result.dimension, props.wantedDimension) || "?",
          patternRepetition: result.patternRepetition || "?",
          patternAdditionalCount: result.patternAdditionalCount,
        })
      }}
    </p>
  </div>
</template>

<script setup>
const props = defineProps({
  useStitches: {
    type: Boolean,
  },
  data: {
    type: Object,
  },
  wantedDimension: {
    type: Number,
  },
});

function getFormattedWidthDifference(dimension, wantedDimension) {
  const difference = dimension - wantedDimension;

  return (difference > 0 ? "+" : "-") + Math.abs(difference);
}
</script>

<style lang="scss" scoped>
@import "./../../../scss/toolResults.scss";
</style>
