<template>
  <header class="app-pattern-form-header">
    <div>
      {{ $t("knitting_gauge.Knitting_gauge") }}
    </div>
    <information-bubble v-bind:colorsInverted="true" position="bottom" width="medium">
      <p>{{ $t("knitting_gauge.Knitting_gauge_explained_1_") }}</p>
      <p>{{ $t("knitting_gauge.Knitting_gauge_explained_2_") }}</p>
      <p>{{ $t("knitting_gauge.Knitting_gauge_explained_3_") }}</p>
    </information-bubble>
  </header>
  <div class="app-pattern-form-side-by-side-container">
    <input-field
      class="app-pattern-form-input-field"
      v-model="internalStitchGauge"
      :label="$t('knitting_gauge.Stitch_count')"
      :description="$t('knitting_gauge.Stitch_gauge_explanation_0')"
      :placeholder="$t('knitting_gauge.Stitch_gauge_example')"
      :small="true"
      @input="stitchGaugeChanged()"
    >
    </input-field>
    <v-spacer></v-spacer>
    <input-field
      class="app-pattern-form-input-field"
      v-model="internalRowGauge"
      :label="$t('knitting_gauge.Row_count')"
      :description="$t('knitting_gauge.Row_gauge_explanation_0')"
      :placeholder="$t('knitting_gauge.Row_gauge_example')"
      :small="true"
      :disabled="internalEstimateRowGaugeEnabled"
    >
    </input-field>
  </div>
  <checkbox-field v-model="internalEstimateRowGaugeEnabled">{{
    $t("knitting_gauge.Estimate_row_gauge_from_stitch_gauge")
  }}</checkbox-field>
</template>

<script setup>
import { ref, watch } from "vue";

import { roundToBase, getRowGaugeFromStitchGauge } from "../lib/calculationUtils";
import CheckboxField from "./general/CheckboxField.vue";
import InformationBubble from "./InformationBubble.vue";
import InputField from "./general/InputField.vue";

const props = defineProps({
  stitchGauge: Number,
  rowGauge: Number,
  estimateRowGaugeEnabled: Boolean,
});

const internalStitchGauge = ref(props.stitchGauge);
const internalRowGauge = ref(props.rowGauge);
const internalEstimateRowGaugeEnabled = ref(props.estimateRowGaugeEnabled);

watch(
  () => props.stitchGauge,
  (value) => {
    internalStitchGauge.value = value;
  }
);

watch(
  () => props.rowGauge,
  (value) => {
    internalRowGauge.value = value;
  }
);

watch(
  () => props.estimateRowGaugeEnabled,
  (value) => {
    internalEstimateRowGaugeEnabled.value = value;
  }
);

const emit = defineEmits();

function stitchGaugeChanged() {
  if (internalEstimateRowGaugeEnabled.value) {
    estimateRowGaugeFromStitchGauge();
  }
}

function estimateRowGaugeFromStitchGauge() {
  internalRowGauge.value = roundToBase(getRowGaugeFromStitchGauge(internalStitchGauge.value), 0.5);
}

watch([internalStitchGauge, internalRowGauge, internalEstimateRowGaugeEnabled], () => {
  emit("update:stitchGauge", internalStitchGauge.value);
  emit("update:rowGauge", internalRowGauge.value);
  emit("update:estimateRowGaugeEnabled", internalEstimateRowGaugeEnabled.value);
});

watch(internalEstimateRowGaugeEnabled, () => {
  estimateRowGaugeFromStitchGauge();
});
</script>

<style lang="scss" scoped>
@import "./../../scss/form.scss";
</style>
