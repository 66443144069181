export function sizesSelectFieldDisabled(formData) {
  return formData.chosenPerson == null || formData.chosenPerson.value == "custom";
}

export function measurementFieldsDisabled(formData) {
  return (
    formData.chosenPerson == null ||
    (formData.chosenPerson.value != "custom" && formData.chosenSize == null)
  );
}

export function hasEmptyValues(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value === null || value === undefined || value === "" || value === 0) {
        return true;
      }
    }
  }
  return false;
}