import axios from "../axios";
import { useAuthStore } from "../stores/auth";

export const getSubscription = async () => {
  const authStore = useAuthStore();
  const response = await axios.get("/subscriptions/me", {
    headers: { Authorization: `Bearer ${authStore.authToken}` },
  });
  const subscription = {
    planId: response.data?.p_subscription_plan_id ?? null,
    nextBillDate: response.data?.p_next_bill_date ?? null,
    nextRetryDate: response.data?.p_next_retry_date ?? null,
    updateUrl: response.data?.p_update_url ?? null,
    cancelUrl: response.data?.p_cancel_url ?? null,
    status: response.data?.p_status ?? null,
    cancellationEffectiveDate: response.data?.p_cancellation_effective_date ?? null,
  };
  return subscription;
};

export const checkIfUserHasAccess = async () => {
  const authStore = useAuthStore();
  const response = await axios.get("/subscriptions/has-access", {
    headers: { Authorization: `Bearer ${authStore.authToken}` },
  });
  return response.data;
};

export const getPartner = async () => {
  const authStore = useAuthStore();
  const response = await axios.get("/partners/me", {
    headers: { Authorization: `Bearer ${authStore.authToken}` },
  });
  if (response.data === null) {
    return null;
  }

  const partner = {
    name: response.data.name,
    createdAt: response.data.created_at,
    expiresAt: response.data.expires_at,
    code: response.data.code,
  };

  return partner;
};
