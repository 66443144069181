<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <h3 class="app-page-title">{{ $t("pricing.Choose_what_fits_you_best") }}</h3>
  <div class="app-page-subtitle">
    {{ $t("subscription.Change_or_cancel_at_any_time_") }}
  </div>
  <subscription-plans :active="true"></subscription-plans>
  <v-row class="button-row" justify="center">
    <localized-link v-if="route.query.next" :to="route.query.next">
      <main-button :dark="true"> {{ $t("subscription.Go_back_to_pattern") }}</main-button>
    </localized-link>
  </v-row>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";

import MainButton from "./../components/general/MainButton.vue";
import SubscriptionPlans from "./../components/SubscriptionPlans.vue";
import LocalizedLink from "../components/LocalizedLink.vue";

const route = useRoute();

const loading = ref(false);
</script>

<style scoped lang="scss">
.button-row {
  margin: 2.5rem 1.5rem;
}
</style>
