<template>
  <div class="app-select-field-container" :class="{ open: isOpen, disabled: disabled }">
    <label>{{ props.label }}</label>
    <div :class="['app-select-field', { small: props.small }]">
      <div class="app-select-field-initial" @click="toggleDropdown">
        <div v-if="selectedOption == null">
          {{ props.placeholder }}
        </div>
        <div v-else>{{ selectedOption.key }}</div>
        <v-icon icon="mdi-chevron-down"></v-icon>
      </div>
      <div class="options" v-show="isOpen">
        <div
          v-for="(option, index) in options"
          :key="option.key"
          @click="selectOption(option)"
          :class="['app-select-field-option', { 'border-bottom': index < options.length - 1 }]"
        >
          {{ option.key }}
        </div>
      </div>
    </div>
    <small v-if="props.description" class="description">{{ description }}</small>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  modelValue: {
    type: Object,
  },
  label: {
    type: String,
  },
  description: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
  },
  items: {
    type: Object,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue", "itemSelected"]);

const isOpen = ref(false);
const selectedOption = ref(props.modelValue);
const options = ref(props.items);

watch(
  () => props.modelValue,
  () => {
    selectedOption.value = props.modelValue;
  },
  { deep: true }
);

const toggleDropdown = () => {
  if (!props.disabled) {
    isOpen.value = !isOpen.value;
  }
};

const selectOption = (option) => {
  if (!props.disabled) {
    selectedOption.value = option;
    isOpen.value = false;
    emit("update:modelValue", selectedOption.value);
    emit("itemSelected", selectedOption.value);
  }
};

const resetSelection = () => {
  selectedOption.value = null;
  isOpen.value = false;
};

defineExpose({
  resetSelection,
});

watch(
  () => props.items,
  () => {
    options.value = props.items;
  },
  { deep: true }
);
</script>

<style lang="scss" scoped>
@import "./../../../scss/styles.scss";

.app-select-field-container {
  text-align: left;
  cursor: pointer;
  position: relative;
}

.app-select-field-container.disabled {
  cursor: default;
}
.app-select-field-container label {
  @include paragraphLargeBold;

  color: var(--knittable-lav-darker);
}
.app-select-field-container.disabled label {
  color: var(--knittable-lav-light);
}

.app-select-field {
  @include subtitleSmall;

  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border: 2px solid var(--knittable-lav-light);

  border: 2px solid var(--knittable-lav-light);
  border-radius: 0.75rem;

  color: var(--knittable-lav-darker);

  width: 100%;
}

.app-select-field:hover {
  border: 2px solid var(--knittable-lav);
}
.disabled .app-select-field {
  color: var(--knittable-lav-light);
}
.disabled .app-select-field:hover {
  border-color: var(--knittable-lav-light);
}

.app-select-field-initial {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  height: 5.25rem;

  width: 100%;
}

.small .app-select-field-initial {
  height: 3.875rem;
}

.app-select-field-option {
  padding: 1.12rem 1.5rem;
}

.options div {
  cursor: pointer;
  transition: background-color 0.3s;
}

.options div.border-bottom,
.open .app-select-field-initial {
  border-bottom: 2px solid var(--knittable-lav-light);
}

.options div:hover {
  background-color: #f0f0f0;
}

.description {
  @include paragraphMediumRegular;

  color: var(--knittable-lav-darker);
  display: block;
}
</style>
