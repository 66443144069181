import { createI18n } from "vue-i18n";

export const defaultLocale = process.env.VUE_APP_I18N_LOCALE || "is";
export const defaultFallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en";

let _i18n;

function setup(
  options = {
    locale: defaultLocale,
    fallbackLocale: defaultFallbackLocale,
  }
) {
  _i18n = createI18n({
    locale: options.locale,
    fallbackLocale: options.fallbackLocale,
    messages: loadLocaleMessages(),
    legacy: false,
  });
}

function loadLocaleMessages() {
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

function getLocale() {
  return _i18n.global.locale
}

function setLocale(newLocale) {
  _i18n.global.locale.value = newLocale;
}

const supportedLocales = {
  da: "DA",
  de: "DE",
  en: "EN",
  fi: "FI",
  is: "IS",
};

export default {
  get vueI18n() {
    return _i18n;
  },
  setup,
  getLocale,
  setLocale,
  defaultLocale,
  supportedLocales
};
