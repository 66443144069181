<template>
  <div :class="['app-checkbox', { small: small }]">
    <v-icon
      class="checked"
      v-if="props.modelValue"
      icon="mdi-checkbox-marked"
      @click="toggleChecked"
    />
    <v-icon class="unchecked" v-else icon="mdi-checkbox-blank" @click="toggleChecked" />
    <label>
      <slot></slot>
    </label>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
  },
  small: {
    type: Boolean,
    defaut: false,
  },
});
const emit = defineEmits(["update:modelValue"]);

const isChecked = ref(false);

const toggleChecked = () => {
  isChecked.value = !isChecked.value;
  emit("update:modelValue", isChecked.value);
};
</script>

<style lang="scss" scoped>
@import "./../../../scss/styles.scss";

.app-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.app-checkbox .unchecked,
.app-checkbox .checked {
  width: 1.9375rem;
  height: 1.9375rem;

  border-radius: 0.25rem;
}

.app-checkbox .unchecked {
  font-size: 2rem;

  color: var(--knittable-isa);
  border: 2px solid var(--knittable-lav-light);
}

.app-checkbox .checked {
  font-size: 2.58rem;
  color: var(--knittable-lav);
  border: 2px solid var(--knittable-lav-light);
}

.app-checkbox.small .checked {
  width: 1.3rem;
  height: 1.3rem;
  font-size: 1.8rem;
}

.app-checkbox.small .unchecked {
  width: 1.3rem;
  height: 1.3rem;
  font-size: 1.1rem;
}

.app-checkbox .checked:hover {
  color: var(--knittable-lav-darker);
}

.app-checkbox .unchecked:hover {
  border-color: var(--knittable-lav);
}

.app-checkbox label {
  @include paragraphMediumBold;

  color: var(--knittable-lav-darker);
  text-align: left;
  padding-left: 1rem;
}
</style>
