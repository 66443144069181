<template>
  <div class="container">
    <div v-if="partner" class="green">
      <div v-if="partner.expiresAt">
        {{
          $t("welcome.You_have_access_via_partnership_program_until", {
            expiresAt: new Date(partner.expiresAt).toLocaleDateString(undefined, {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }),
          })
        }}
      </div>
      <div v-else>
        {{ $t("welcome.You_have_access_via_partnership_program") }}
      </div>
      <div v-if="partner.code" class="subnote">
        {{
          $t("welcome.Your_customers_can_use_the_code", {
            code: partner.code,
          })
        }}
      </div>
    </div>
    <div v-else :class="[{ green: remainingDays > 0, pink: remainingDays == 0 }]">
      {{
        $t("welcome.You_have_x_left_of_your_free_trial", {
          remainingDays: remainingDays,
        })
      }}
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";

import { getPartner } from "../services/access";
import { useAuthStore } from "../stores/auth";

const authStore = useAuthStore();

const userCreatedAt = ref(null);
const partner = ref(null);

const loading = ref(false);

onMounted(() => {
  loadUserInfo();
});

async function loadUserInfo() {
  try {
    loading.value = true;

    const user = await authStore.getUser();
    userCreatedAt.value = new Date(user.createdAt);

    partner.value = await getPartner();
  } catch (error) {
    alert(error.msg);
  } finally {
    loading.value = false;
  }
}

const remainingDays = computed(() => {
  if (userCreatedAt.value === null) {
    return null;
  }
  const datetimeNow = new Date();
  const timeDifference = datetimeNow.getTime() - userCreatedAt.value.getTime();
  const daysDifference = timeDifference / (1000 * 3600 * 24);

  return Math.max(0, Math.round(14 - daysDifference));
});
</script>

<style scoped lang="scss">
@import "../../scss/styles.scss";

.container {
  @include subtitleSmall;

  margin: 0 1.5rem 1.5rem;
}

.green {
  color: var(--knittable-jade);
}

.pink {
  color: var(--knittable-begonia);
}

.subnote {
  @include paragraphLargeRegular;

  margin-top: 1rem;
}
</style>
