<template>
  <wanted-pattern
    :data="props.data"
    :disabled="props.disabled"
    v-model:bookmark="internalBookmark"
  ></wanted-pattern>
</template>

<script setup>
import { defineAsyncComponent, watch, computed } from "vue";

const props = defineProps({
  currentVersion: {
    type: String,
  },
  versions: {
    type: Object,
  },
  bookmark: {
    type: String,
  },
  data: {
    type: Object,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  bookmark: {
    type: String,
  },
});

const internalBookmark = computed({
  get: () => props.bookmark,
  set: (value) => {
    emit("update:bookmark", value);
  },
});

const emit = defineEmits();

watch(
  () => props.currentVersion,
  () => {
    wantedPattern = defineAsyncComponent(dynamicImport);
  },
  { deep: true }
);

const dynamicImport = async () => {
  return import(`./${props.versions[props.currentVersion]}.vue`);
};

let wantedPattern = defineAsyncComponent(dynamicImport);
</script>
