export function composeRoute(locale, to, next) {
  const route = {
    name: to ?? 'home',
    params: { locale: locale },
  };

  if (next != null) {
    route.query = { next: next };
  }

  return route;
}
