import mem from "mem";

import mp from "./../userTracking/mixpanel";
import axios from "../axios";
import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    authToken: useLocalStorage("access_token", null),
    refreshToken: useLocalStorage("refresh_token", null),
  }),
  actions: {
    async signIn(email, password) {
      const data = { username: email, password: password };

      const response = await axios.post("/auth/signin", new URLSearchParams(data));
      this.authToken = response.data.access_token;
      this.refreshToken = response.data.refresh_token;
      localStorage.setItem("access_token", this.authToken);
      localStorage.setItem("refresh_token", this.refreshToken);

      const user = await this.getUser();
      mp.identify(user.id);

      return;
    },
    signOut() {
      this.authToken = null;
      this.refreshToken = null;
      mp.unidentify();
    },
    async signUp(
      email,
      password,
      passwordConfirm,
      locale,
      canSendEmails,
      acceptsTerms,
      termsVersion,
      canTrack = null
    ) {
      await axios.post("/auth/signup", {
        email: email,
        password: password,
        password_confirm: passwordConfirm,
        language: locale,
        can_send_emails: canSendEmails,
        accepts_terms: acceptsTerms,
        can_track: canTrack,
        terms_version: termsVersion,
      });
    },
    async refresh() {
      const response = await axios.get("/auth/refresh", {
        headers: { Authorization: `Bearer ${this.refreshToken}` },
      });
      this.authToken = response.data.access_token;
      localStorage.setItem("access_token", this.authToken);
      return;
    },
    memoizedRefresh: mem(
      function () {
        return this.refresh();
      },
      { maxAge: 10000 }
    ),
    async getUser() {
      const response = await axios.get("/auth/me", {
        headers: { Authorization: `Bearer ${this.authToken}` },
      });
      const user = {
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        email: response.data.email,
        id: response.data.id,
        verified: response.data.verified,
        createdAt: response.data.created_at,
      };
      return user;
    },
    async verifyEmail(token) {
      await axios.get(`/auth/verify-email/${token}`);
    },
    async resendVerificationEmail(token, email, locale) {
      await axios.post(`/auth/resend-verification-email/`, {
        token: token,
        email: email,
        language: locale,
      });
    },
    async forgotPassword(email, locale) {
      await axios.post(`/auth/forgot-password`, {
        email: email,
        language: locale,
      });
    },
    async deleteAccount() {
      await axios.delete(`/users/me`, {
        headers: { Authorization: `Bearer ${this.authToken}` },
      });
      this.signOut();
    },
    async resetPassword(newPassword, confirmPassword, code = null) {
      const endpoint = code ? `/auth/reset-password/${code}` : `/auth/reset-password/`;
      const config = code ? {} : { headers: { Authorization: `Bearer ${this.authToken}` } };

      const requestData = {
        new_password: newPassword,
        password_confirm: confirmPassword,
      };

      await axios.post(endpoint, requestData, config);
    },
    async updateUser(firstName, lastName) {
      const body = {
        first_name: firstName,
        last_name: lastName,
      };
      await axios.put("users/me", body, {
        headers: { Authorization: `Bearer ${this.authToken}` },
      });
    },
  },
});
