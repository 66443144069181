<template>
  <v-card-text>
    <v-form v-model="passwordsValid" @submit.prevent>
      <v-text-field
        v-model="internalnewPassword"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        :rules="passwordRules"
        :type="showPassword ? 'text' : 'password'"
        hint="At least 8 characters"
        :label="$t('common_user_settings.New_password')"
        required
      ></v-text-field>

      <v-text-field
        v-model="internalConfirmPassword"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        :rules="passwordConfirmRules(newPassword)"
        :type="showPassword ? 'text' : 'password'"
        hint="At least 8 characters"
        :label="$t('common_user_settings.Confirm_password')"
        required
      ></v-text-field>
    </v-form>
  </v-card-text>
</template>

<script setup>
import { ref, watch } from "vue";

import { passwordRules, passwordConfirmRules } from "./../lib/formRules.js";

const props = defineProps({
  newPassword: String,
  confirmPassword: String,
});

const emit = defineEmits(["update:newPassword", "update:confirmPassword", "update:passwordsValid"]);

const internalnewPassword = ref(props.newPassword);
const internalConfirmPassword = ref(props.confirmPassword);

const showPassword = ref(false);

const passwordsValid = ref(false);

watch([internalnewPassword, internalConfirmPassword], () => {
  emit("update:newPassword", internalnewPassword.value);
  emit("update:confirmPassword", internalConfirmPassword.value);
});

watch(passwordsValid, () => {
  emit("update:passwordsValid", passwordsValid.value);
});
</script>
