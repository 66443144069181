<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <main-button v-if="!verificationResent" @click="resendVerification">{{
    $t("verify_email.Click_here_to_resend_verification_email")
  }}</main-button>
  <v-card-text v-else>
    {{ $t("verify_email.Verification_email_has_been_resent") }}
  </v-card-text>
</template>

<script setup>
import { ref } from "vue";

import { useAuthStore } from "../stores/auth";
import { useLangStore } from "./../stores/lang";

import MainButton from "./general/MainButton.vue";

const authStore = useAuthStore();
const langStore = useLangStore();

const locale = langStore.getLocale();

const props = defineProps({
  token: {
    type: String,
    default: null,
  },
  email: {
    type: String,
    default: null,
  },
});

const loading = ref(false);

const verificationResent = ref(false);

async function resendVerification() {
  try {
    loading.value = true;
    await authStore.resendVerificationEmail(props.token, props.email, locale.value);
    verificationResent.value = true;
  } catch (error) {
    alert(error.msg);
  } finally {
    loading.value = false;
  }
}
</script>
