<template>
  <v-row
    :class="[
      'app-how-to-step-row',
      {
        'app-how-to-step-1': props.stepNumber % 3 == 1,
        'app-how-to-step-2': props.stepNumber % 3 == 2,
        'app-how-to-step-3': props.stepNumber % 3 == 0,
      },
    ]"
    justify="center"
  >
    <v-col v-if="reverseNeeded" cols="12" sm="12" md="6" class="app-how-to-img-col">
      <div class="app-how-to-img"></div>
    </v-col>
    <v-col v-if="!mdAndUp" cols="12" sm="12" md="1">
      <span class="app-how-to-number">{{ props.stepNumber }}</span>
    </v-col>
    <v-col cols="12" sm="12" md="5">
      <div class="app-how-to-step">
        <span v-if="mdAndUp" class="app-how-to-number">{{ props.stepNumber }}</span>
        <span class="app-how-to-step-instructions">
          <div class="app-how-to-step-title">{{ props.stepTitle }}</div>
          <div class="app-how-to-step-subtitle">
            {{ props.stepSubtitle }}
          </div>
          <div class="app-how-to-step-text">
            {{ props.stepText }}
          </div></span
        >
      </div>
    </v-col>
    <v-col v-if="!reverseNeeded" cols="12" sm="12" md="6" class="app-how-to-img-col">
      <div class="app-how-to-img"></div>
    </v-col>
  </v-row>
</template>

<script setup>
import { useDisplay } from "vuetify";

const props = defineProps({
  stepNumber: {
    type: Number,
  },
  stepTitle: {
    type: String,
  },
  stepSubtitle: {
    type: String,
  },
  stepText: {
    type: String,
  },
  stepImg: {
    type: String,
  },
  reverseOrder: {
    type: Boolean,
    default: false,
  },
});

const { mdAndUp } = useDisplay();

let reverseNeeded = props.reverseOrder & mdAndUp;
</script>

<style>
.app-how-to-step {
  display: flex;
}

.app-how-to-number {
  width: 76px;
  height: 76px;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 2.6875rem;
  color: var(--knittable-isa);
  font-family: Philosopher;
  font-size: 2rem;
  font-weight: 700;
  display: flex;
  flex-shrink: 0;
}

.app-sm-and-down .app-how-to-number {
  margin-left: 1.5rem;
  text-align: left;

  width: 49px;
  height: 49px;
}

.app-how-to-step-1 .app-how-to-number {
  background: var(--knittable-yama);
}

.app-how-to-step-2 .app-how-to-number {
  background: var(--knittable-lav-darker);
}

.app-how-to-step-3 .app-how-to-number {
  background: var(--knittable-begonia);
}

.app-how-to-step-instructions {
  margin-left: 3rem;
  text-align: left;
}

.app-sm-and-down .app-how-to-step-instructions {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.app-how-to-step-title {
  color: var(--knittable-isa);
  font-family: Philosopher;
  font-size: 4rem;
  font-weight: 700;
  line-height: 110%;
}

.app-sm-and-down .app-how-to-step-title {
  font-size: 2.25rem;
}

.app-how-to-step-subtitle {
  color: var(--knittable-isa);
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  line-height: 120%;

  margin-top: 2rem;
}

.app-sm-and-down .app-how-to-step-subtitle {
  font-size: 1.5rem;
}

.app-how-to-step-text {
  color: var(--knittable-isa);
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 160%;

  margin-top: 2rem;
}

.app-sm-and-down .app-how-to-step-text {
  font-size: 1.35rem;
}

.app-how-to-img {
  width: 555px;
  aspect-ratio: 1;
  border-radius: 8.79538rem;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.app-sm-and-down .app-how-to-img {
  margin-left: 1.5rem;
  margin-right: 3rem;
  border-radius: 5.19594rem;
}

.app-how-to-step-1 .app-how-to-img {
  border: 4px solid var(--knittable-yama);
  filter: drop-shadow(20.78375px 20.78375px var(--knittable-yama));
  background-image: url("./../assets/measure.png");
}

.app-how-to-step-2 .app-how-to-img {
  border: 4px solid var(--knittable-lav);
  filter: drop-shadow(20.78375px 20.78375px var(--knittable-lav));
  background-image: url("./../assets/yarns.png");
}

.app-how-to-step-3 .app-how-to-img {
  border: 4px solid var(--knittable-begonia);
  filter: drop-shadow(20.78375px 20.78375px var(--knittable-begonia));
  background-image: url("./../assets/woman_knitting.jpg");
}

.app-how-to-img-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-how-to-step-row {
  place-items: center;
  height: 100%;
  margin-bottom: 10rem;
}

.app-sm-and-down .app-how-to-step-row {
  place-items: center;
  height: 100%;
  margin-bottom: 3.5rem;
}
</style>
