import { defineStore } from "pinia";

import i18n from "../i18n";

export const useLangStore = defineStore("lang", {
  getters: {
    getSupportedLocales: (state) => i18n.supportedLocales,
    getDefaultLocale: (state) => i18n.defaultLocale,
    getLocale: (state) => i18n.getLocale,
  },
  actions: {
    setLocale(locale) {
      i18n.setLocale(locale)
    }
  }
});
