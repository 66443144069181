<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <div v-if="!hasChosen" class="behavior-tracking-dialog">
    <div class="dialog-overlay">
      <div class="dialog-content">
        <p>{{ $t("tracking_behavior.We_would_like_to_track") }}</p>
        <div class="button-container">
          <button @click="allowTracking">{{ $t("tracking_behavior.Yes") }}</button>
          <button @click="denyTracking">{{ $t("tracking_behavior.No") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

import { getConsentFromLocalStorage, setConsentInLocalStorage } from "./../lib/userTrackingUtils";
import { saveUserTrackingPreference } from "./../services/userTrackingPreference";
import mp from "../userTracking/mixpanel";
import { useAuthStore } from "../stores/auth";

const loading = ref(false);

const authStore = useAuthStore();

const hasChosen = ref(checkIfChosen());

const allowTracking = () => {
  mp.optIn();
  setConsent(true);
};

const denyTracking = () => {
  mp.optOut();
  setConsent(false);
};

function checkIfChosen() {
  const [canTrack, savedAt] = getConsentFromLocalStorage();
  const expiresAt = new Date(savedAt);
  expiresAt.setMonth(expiresAt.getMonth() + 6);

  return canTrack != null && new Date() < new Date(expiresAt);
}

async function setConsent(canTrack) {
  setConsentInLocalStorage(canTrack);

  if (authStore.authToken) {
    await savePreference(canTrack);
  }

  hasChosen.value = true;
}

async function savePreference(canTrack) {
  try {
    loading.value = true;
    await saveUserTrackingPreference(canTrack);
  } catch (error) {
    alert(error.msg);
  } finally {
    loading.value = false;
  }
}
</script>

<style scoped lang="scss">
@import "./../../scss/styles.scss";

.behavior-tracking-dialog {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  pointer-events: none;
  z-index: 999;
}

.dialog-overlay {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 90%;
  pointer-events: none; /* Allow clicks to pass through to underlying elements */
}

.dialog-content {
  @include paragraphMediumBold;
  text-align: center;
  background-color: var(--knittable-opal);
  color: var(--knittable-isa);
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 2.5rem 2.5rem 0rem 0rem;
  pointer-events: auto;
}

.button-container {
  margin-top: 10px;
}

button {
  margin: 0 5px;
  padding: 8px 16px;
  cursor: pointer;
}
</style>
