<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <v-row align="center" justify="center" no-gutters style="height: 350px">
    <main-button
      v-if="!hasVerified && !verificationError"
      @click="verifyEmail()"
    >
    {{ $t('verify_email.Verify_my_email_address') }}
    </main-button>
    <v-card>
      <v-card-text v-if="hasVerified">
        {{ $t("verify_email.Your_email_address_has_been_verified_") }}
        <localized-link to="signin" next="welcome">
          {{ $t("verify_email.Sign_in_to_continue") }}
        </localized-link>
      </v-card-text>

      <v-card-text v-else-if="verificationError">
        <v-icon icon="mdi-alert-circle-outline" />
        {{ verificationError }}
      </v-card-text>
      <resend-verification-button
        v-else-if="resendVerificationOption"
        :token="token"
      ></resend-verification-button>
    </v-card>
  </v-row>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";

import { useAuthStore } from "../stores/auth";
import LocalizedLink from "../components/LocalizedLink.vue";
import MainButton from "../components/general/MainButton.vue";
import ResendVerificationButton from "../components/ResendVerificationButton.vue";

const authStore = useAuthStore();

const route = useRoute();
const token = route.params.token;

const hasVerified = ref(false);
const verificationError = ref(null);
const resendVerificationOption = ref(false);
const loading = ref(false);

async function verifyEmail() {
  try {
    loading.value = true;
    await authStore.verifyEmail(token);
    hasVerified.value = true;
  } catch (error) {
    verificationError.value = error.msg;
    if (error.response && error.response.data.detail == "VERIFICATION_TOKEN_EXPIRED") {
      resendVerificationOption.value = true;
    }
  } finally {
    loading.value = false;
  }
}
</script>
