<template>
  <toggle-field
    :label="t('common.Ribbing_type')"
    :options="cuffPatternRepOptions"
    v-model="selectedOption"
  ></toggle-field>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";

import ToggleField from "./../general/ToggleField.vue";

const { t } = useI18n();

const props = defineProps({
  modelValue: {
    type: Object,
  },
});
const emit = defineEmits(["update:modelValue"]);

const selectedOption = ref(props.modelValue);

watch(
  () => props.modelValue,
  () => {
    selectedOption.value = props.modelValue;
  },
  { deep: true }
);

watch(
  () => selectedOption.value,
  (value) => {
    emit("update:modelValue", value);
  }
);

const cuffPatternRepOptions = computed(() => {
  return [
    { key: t("common.Single_rib_option"), value: 2 },
    { key: t("common.Double_rib_option"), value: 4 },
  ];
});
</script>
