export default {
  "common": {
    "Beanie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mützen"])},
    "Mittens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fäustlinge"])},
    "Socks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socken"])},
    "Sweater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pullover"])},
    "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maße"])},
    "Choose_a_size_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größenkategorie auswählen"])},
    "Size_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größenkategorie"])},
    "Child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinder"])},
    "Grown_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwachsene"])},
    "Custom_tailored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuelle Maße"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monate"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahre"])},
    "Calculate_pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anleitung berechnen"])},
    "Knitting_gauge_from_swatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschenprobe mit Probestück ermitteln"])},
    "Advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Optionen"])},
    "Ribbing_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rippenmuster"])},
    "Single_rib_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfach (1 M re, 1 M li)"])},
    "Double_rib_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppelt (2 M re, 2 M li)"])}
  },
  "footer": {
    "Slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittable: Stricken, so wie es dir passt."])},
    "Knitting_calculations_can_be_time_consuming_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Umrechnen und Anpassen einer Strickanleitung kostet oft Nerven und Zeit - Zeit, die man viel lieber strickend verbringen würde. Ein isländisches Sprichwort besagt \"Drjúg er lykkjustundin\" - Strickzeit ist kostbar. Mit Knittable könnt ihr diese lästige Vorarbeit auf ein Minimum reduzieren, und die Strickzeit dafür richtig auskosten."])},
    "Supported_by_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützt durch:"])}
  },
  "knitting_gauge": {
    "Knitting_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschenprobe"])},
    "Stitch_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschenanzahl"])},
    "Row_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenanzahl"])},
    "Knitting_gauge_explained_1_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Maschenprobe wird in Maschen und Reihen gemessen: Die Maschenprobe in Maschen zeigt an, wie viele Maschen ein 10 cm breites Stück ergeben. Die Maschenprobe in Reihen zeigt an, wie viele Reihen benötigt werden, um ein 10 cm langes Stück zu erhalten."])},
    "Knitting_gauge_explained_2_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die meisten Garne geben eine standardisierte Maschenprobe für eine bestimmte Nadelstärke an. Wenn eure Strickweise in etwa diesem Standard entspricht, könnt ihr versuchen, diese Maschenprobe mit der angegebenen Nadelstärke zu erreichen."])},
    "Knitting_gauge_explained_3_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ihr eher fest oder locker strickt, könnt ihr die empfohlene Maschenprobe erreichen, indem ihr eine größere oder kleinere Nadelstärke als die angegebene verwendet (dickere Nadeln, wenn ihr fest strickt - dünnere Nadeln, wenn ihr locker strickt). Für ein optimales Ergebnis empfehlen wir immer, zuerst eine Maschenprobe anzufertigen, bevor ihr das neue Projekt angeht."])},
    "Stitch_gauge_explanation_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzahl der Maschen auf 10 cm"])},
    "Stitch_gauge_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z.B. 18 Maschen für Léttlopi"])},
    "Row_gauge_explanation_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzahl der Reihen auf 10 cm"])},
    "Row_gauge_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z.B. 24 Reihen für Léttlopi"])},
    "Estimate_row_gauge_from_stitch_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Reihenanzahl anhand der Maschenbreite errechnen"])}
  },
  "socks": {
    "Shoe_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schuhgröße"])},
    "Choose_a_shoe_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schuhgröße auswählen"])},
    "Ankle_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beinumfang"])},
    "Ankle_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Beinumfang am Knöchel (cm)"])},
    "Type_in_ankle_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beinumfang eingeben"])},
    "Foot_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fußumfang"])},
    "Foot_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Umfang um den Fußballen (cm)"])},
    "Type_in_foot_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fußumfang eingeben"])},
    "Sock_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schaftlänge"])},
    "Sock_height_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Schaftlänge vom Maschenanschlag bis zur Ferse (in cm)"])},
    "Type_in_sock_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schaftlänge eingeben"])},
    "Sock_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fußlänge"])},
    "Sock_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fußlänge von der Ferse zu den Zehen (in cm)"])},
    "Type_in_sock_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fußlänge eingeben"])},
    "Leg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schaft"])},
    "Leg_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Schlagt ", _interpolate(_named("castOnStitchCount")), " Maschen auf 4 Nadeln eines Nadelspiels an (", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " und ", _interpolate(_named("stitchCountEachEvenNeedle")), " Maschen pro Nadel). Verwendet dabei die Nadelstärke, mit der ihr die angegebene Maschenprobe erreicht. Schließt zur Runde und strickt ein Bündchen im Rippenmuster (", _interpolate(_named("cuffPatternRepCountHalf")), " M re, ", _interpolate(_named("cuffPatternRepCountHalf")), " M li im Wechsel), bis das Stück ", _interpolate(_named("cuffLength")), " cm misst."])},
    "Leg_paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt dann ", _interpolate(_named("anklePieceLength")), " cm glatt rechts."])},
    "Heel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferse"])},
    "Heel_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jetzt strickt ihr die Fersenwand: Strickt die Maschen von Nadel 1 und wendet dann die Arbeit. Hebt die erste Masche ungestrickt, wie zum li stricken, ab, und strickt alle weiteren Maschen von Nadel 1 und Nadel 4 links auf dieselbe Nadel. Jetzt sollten ", _interpolate(_named("castOnStitchCount_half")), " Maschen auf Nadel 1 liegen. Diese Maschen bilden den Beginn der Fersenwand. Strickt diese Maschen jetzt glatt rechts in Reihen (d.h. re in der Hinreihe, li in der Rückreihe, und hebt die erste Masche in jeder Reihe ungestrickt ab), bis ihr ab der ersten Reihe (die Rückreihe, in der ihr die Maschen vereint habt) insgesamt ", _interpolate(_named("heelFlapRowCount")), " Reihen gestrickt habt. Die nächste Reihe sollte jetzt eine Hinreihe sein."])},
    "Heel_next_up_heel_decreases_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt formt ihr das Fersenkäppchen durch Abnahmen an beiden Seiten der Fersenwand:"])},
    "Heel_first_decrease_row_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Abnahmereihe:"])},
    "Heel_first_decrease_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hebt den ersten Stich ungestrickt, wie zum re stricken, ab. Strickt re weiter, bis ", _interpolate(_named("heelFlapMarginStitchCountPlusOne")), " Maschen vor Ende der Nadel. Hebt 1 M wie zum re stricken ab, strickt 1 M re und hebt die abgehobene M darüber. Wendet die Arbeit."])},
    "Heel_second_row_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweite Reihe:"])},
    "Heel_second_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hebt die erste Masche ungestrickt, wie zum li stricken, ab. Strickt li weiter bis ", _interpolate(_named("heelFlapMarginStitchCountPlusOne")), " Maschen vor Ende der Nadel. Strickt 2 M li zusammen und wendet die Arbeit."])},
    "Heel_paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wiederholt Reihen 1 und 2. Strickt dabei immer, bis erst ", _interpolate(_named("heelFlapMarginStitchCount")), ", dann ", _interpolate(_named("heelFlapMarginStitchCountMinusOne")), ", dann ", _interpolate(_named("heelFlapMarginStitchCountMinusTwo")), ", etc. Maschen am Ende der Nadel übrig bleiben, bis alle Randmaschen ins Fersenkäppchen integriert sind."])},
    "Foot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuß"])},
    "Foot_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ab hier wird der Fuß wieder in Runden über alle Maschen gestrickt. Teilt die Maschen des Käppchens wieder auf Nadeln 1 und 4 auf und fasst auf beiden Seiten die ", _interpolate(_named("heelFlapEdgeStitchCount")), " Randmaschen der Fersenwand auf diese Nadeln auf. Fasst am Ende auf beiden Seiten je eine zusätzliche Masche aus der letzten Runde vor der Fersenwand auf, um Löcher zu vermeiden. Der Rundenbeginn liegt jetzt wieder zwischen Nadel 1 und 4."])},
    "Knit_the_first_two_rounds_the_following_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strickt die ersten zwei Runden wie folgt:"])},
    "Knit_a_round_with_twisted_edge_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strickt eine Runde re. Strickt die neu aufgefassten Randmaschen dabei verschränkt ab."])},
    "Knit_a_round_with_decreases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strickt jetzt die Maschen von Nadel 1, bis 3 Maschen vor Ende der Nadel. Strickt 2 Maschen re zusammen und strickt die letzte Masche re. Strickt die Maschen von Nadeln 2 und 3 re. Strickt die erste Masche von Nadel 4 re, hebt die nächste Masche ungestrickt wie zum re stricken ab, strickt die nächste Masche re und hebt die abgehobene Masche darüber. Strickt die Runde re zu Ende."])},
    "Repeat_the_two_rounds_above_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wiederholt Runden 2 und 3, bis ihr insgesamt ", _interpolate(_named("footStitchCount")), " M auf den Nadeln habt."])},
    "Knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt in Runden weiter, bis der Fuß ab der Ferse ", _interpolate(_named("footLengthMinusTipLength")), " cm lang ist."])},
    "Toe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spitze"])},
    "Make_sure_equal_number_of_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellt sicher, dass die Maschenzahl auf Nadeln 1 und 4 (Ferse) dieselbe ist wie auf Nadeln 2 und 3 (Spann), bevor ihr die Abnahmen für die Spitze strickt."])},
    "Work_to_decreases_in_the_following_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Abnahmen werden wie folgt gestrickt:"])},
    "First_round_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Runde (Abnahmerunde):"])},
    "Decrease_round_description_first_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstNeedle")), " Strickt bis ", _interpolate(_named("tipMarginStitchCountPlusTwo")), " Maschen vor Ende der Nadel. Strickt 2 Maschen zusammen", _interpolate(_named("conditionalAdditionalLastStitch")), "."])},
    "_and_then_knit_x_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([", strickt ", _interpolate(_named("tipMarginStitchCount")), " Maschen re"])},
    "Decrease_round_description_second_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("secondNeedle")), " ", _interpolate(_named("conditionalAdditionalFirstStitch")), "Hebt 1 Masche ungestrickt wie zum re stricken ab, strickt die nächste Masche re und hebt die abgehobene Masche darüber. Strickt die übrigen Maschen von Nadel 2"])},
    "Knit_one_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strickt 1 Masche re. "])},
    "Decrease_round_description_third_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("thirdNeedle")), " Wie Nadel 1."])},
    "Decrease_round_description_fourth_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fourthNeedle")), " Wie Nadel 2."])},
    "Knit_decrease_rounds_from_here_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt ab jetzt jede weitere Runde als Abnahmerunde, bis nur noch insgesamt ", _interpolate(_named("finalRowStitchCount")), " Maschen auf den Nadeln liegen. Schneidet den Faden ab und fädelt das Ende durch die verbleibenden Maschen. Vernäht alle Fäden."])},
    "Knit_the_other_sock_the_same_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strickt die zweite Socke gleich."])},
    "v1_1_0": {
      "Leg_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Schlagt ", _interpolate(_named("castOnStitchCount")), " Maschen auf vier Nadeln des Nadelspiels an (je ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " und ", _interpolate(_named("stitchCountEachEvenNeedle")), " Maschen pro Nadel). Verwendet alternativ eine sehr kurze Rundstricknadel, oder eine sehr lange (80 cm+) für Magic Loop. Schließt zur Runde und strickt das Bündchen im Rippenmuster (", _interpolate(_named("cuffPatternRepCountHalf")), " M re, ", _interpolate(_named("cuffPatternRepCountHalf")), " M li im Wechsel), bis es ", _interpolate(_named("cuffLength")), " cm lang ist."])},
      "Heel_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beginnt jetzt mit der Fersenwand. Strickt dafür die ", _interpolate(_named("heelflapStitchCountLeft")), " Maschen der ersten Nadel rechts und wendet dann die Arbeit. Hebt die erste Masche ungestrickt ab und strickt die übrigen ", _interpolate(_named("heelflapStitchCountLeftMinusOne")), " Maschen von Nadel 1 links. Hängt einen Maschenmarkierer auf die Nadel und strickt dann die nächsten ", _interpolate(_named("heelflapStitchCountRight")), " Maschen von Nadel 4 links auf dieselbe Nadel. Jetzt sollten ", _interpolate(_named("heelflapStitchCount")), " Maschen auf der Nadel liegen (die kombinierten Maschen von Nadeln 1 und 4). Aus diesen Maschen strickt ihr die Fersenwand."])},
      "Heel_paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt die ", _interpolate(_named("heelflapStitchCount")), " Maschen der Fersenwand jetzt glatt rechts in Reihen (re in der Hinreihe, li in der Rückreihe), und hebt dabei die erste Masche in jeder Reihe ungestrickt ab, bis ihr insgesamt ", _interpolate(_named("heelFlapRowCount")), " Reihen gestrickt habt. Jetzt solltet ihr ", _interpolate(_named("heelFlapEdgeStitchCount")), " lange Randmaschen zählen können. Die nächste Reihe sollte eine Hinreihe sein."])},
      "Heel_first_decrease_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hebt die erste Masche wie zum rechts stricken ab und strickt bis zum Maschenmarkierer in der Mitte der Nadel. Hebt ihn ab und strickt noch ", _interpolate(_named("heelFlapCenterLeftMinusOne")), " weitere Masche(n). Hebt 1 Masche wie zum rechts stricken ab, strickt die nächste Masche re und hebt die abgehobene Masche darüber. Wendet die Arbeit."])},
      "Heel_second_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hebt die erste Masche wie zum links stricken ab und strickt li bis zum Maschenmarkierer. Hebt ihn ab und strickt die nächsten ", _interpolate(_named("heelFlapCenterRightMinusOne")), " Masche(n) links. Strickt die nächsten 2 Maschen li zusammen und wendet die Arbeit. Die ", _interpolate(_named("heelFlapCenterStitchCount")), " Maschen, die zwischen den beiden Abnahmen liegen, bilden das Käppchen."])},
      "Heel_paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wiederholt Reihen 1 und 2, bis ihr alle Maschen beidseitig der ", _interpolate(_named("heelFlapCenterStitchCount")), " mittleren Käppchenmaschen abgenommen habt."])},
      "Heel_paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hebt die erste Masche ungestrickt, wie zum rechts stricken, ab und strickt die nächste(n) ", _interpolate(_named("heelFlapCenterRightMinusOne")), " Masche(n) rechts. Jetzt solltet ihr wieder den Maschenmarkierer in der Mitte erreicht haben."])},
      "Foot_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ab hier strickt ihr den Fuß wieder in Runden. Teilt die  ", _interpolate(_named("heelFlapCenterStitchCount")), " Maschen des Käppchens am Maschenmarkierer (dem Rundenbeginn) und legt sie wieder auf Nadeln 1 und 4. Fasst dann auf beiden Seiten der Fersenwand die ", _interpolate(_named("heelFlapEdgeStitchCount")), " Randmasche auf und legt sie zu den Käppchenmaschen auf die Nadeln. Fasst am oberen Ende der Fersenwand, am Übergang zum Spann, noch je eine weitere Masche auf, um an dieser Stelle Löcher zu vermeiden. Die Runde beginnt jetzt wie vorher mit Nadel 1, in der Mitte der Käppchenmaschen."])},
      "Foot_paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jetzt sollten insgesamt ", _interpolate(_named("totalStitchCountBeforeHeelDecrease")), " Maschen auf der Nadel liegen, d.h. ", _interpolate(_named("firstNeedleStitchCount")), ", ", _interpolate(_named("secondNeedleStitchCount")), ", ", _interpolate(_named("thirdNeedleStitchCount")), " und ", _interpolate(_named("fourthNeedleStitchCount")), " Maschen auf Nadeln 1, 2, 3 und 4. Wenn ihr eine Rundstricknadel verwendet, sollten die Stellen, wo Nadel 1 auf Nadel 2 treffen würde, und wo Nadel 3 auf Nadel 4 treffen würde, jeweils mit einem Maschenmarkierer markiert werden, den ihr zwischen zwei Maschen auf die Nadel hängt."])},
      "Knit_a_round_with_decreases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strickt die Maschen von Nadel 1 bis 2 Maschen vor Ende der Nadel (oder 2 Maschen vor dem ersten Maschenmarkierer) und strickt diese 2 Maschen re zusammen. Strickt alle Maschen von Nadel 2 und 3 (oder bis zum zweiten Maschenmarkierer). Hebt die erste Masche von Nadel 4 ungestrickt, wie zum links stricken, ab, strickt die nächste Masche re und hebt die ungestrickte Masche darüber. Strickt die Nadel/Runde zu Ende."])},
      "Repeat_the_two_rounds_above_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wiederholt Runden 2 und 3 noch ", _interpolate(_named("heelDecreaseCountMinusOne")), " Mal, bis ", _interpolate(_named("footStitchCount")), " Maschen auf der Nadel liegen."])},
      "Make_sure_equal_number_of_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kontrolliert jetzt noch einmal die Verteilung der Maschen auf dem Nadelspiel: Bevor ihr mit den Abnahmen für die Spitze beginnt, sollten ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " und ", _interpolate(_named("stitchCountEachEvenNeedle")), " auf Nadeln 1, 2, 3 und 4 liegen. Wenn ihr eine Rundstricknadel verwendet, sollten die Stellen, wo Nadel 1 auf Nadel 2 treffen würde, und wo Nadel 3 auf Nadel 4 treffen würde, jeweils mit einem Maschenmarkierer markiert sein."])}
    },
    "pattern_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Socken werden am Rippenbündchen begonnen und zur Spitze (d.h. top-down) gestrickt. Der Fuß wird glatt rechts, mit einer klassischen Käppchenferse gestrickt. Die Spitze wird mit regelmäßigen Abnahmen geformt. Für die perfekte Passform berechnen wir die Socken mit einem Umfang, der 10% unter dem tatsächlichen Beinumfang liegt."])}
  },
  "common2": {
    "First_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Runde:"])},
    "Second_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweite Runde:"])},
    "Second_and_third_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweite und dritte Runde:"])},
    "Second_to_fourth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweite bis vierte Runde:"])},
    "Third_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dritte Runde:"])},
    "Fourth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierte Runde:"])},
    "Fifth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fünfte Runde:"])},
    "Sixth_and_seventh_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sechste und siebte Runde:"])},
    "Eighth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achte Runde:"])},
    "Ninth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neunte Runde:"])},
    "First_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Nadel:"])},
    "Second_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweite Nadel:"])},
    "Third_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dritte Nadel:"])},
    "Fourth_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierte Nadel:"])},
    "Knit_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strickt alle Maschen rechts."])},
    "Knit_a_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strickt eine Abnahmerunde."])}
  },
  "mittens": {
    "Cuff_circumference_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfang"])},
    "Cuff_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfang um das (eigene) Handgelenk (cm)"])},
    "Type_in_cuff_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfang um das Handgelenk eingeben"])},
    "Cuff_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bündchenlänge"])},
    "Cuff_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bündchenlänge (cm)"])},
    "Type_in_cuff_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bündchenlänge eingeben"])},
    "Hand_circumference_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfang über den Handrücken"])},
    "Hand_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfang über den (eigenen) Handrücken (cm)"])},
    "Type_in_hand_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfang über den Handrücken eingeben"])},
    "Hand_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handlänge"])},
    "Hand_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handlänge vom Handgelenk zu den Fingerspitzen (cm)"])},
    "Type_in_hand_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handlänge eingeben"])},
    "Felting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filzen"])},
    "Felting_information_bubble_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ihr die Handschuhe später filzen wollt, müsst ihr sie entsprechend größer stricken. Wenn sie fertig sind, können sie von Hand oder in der Waschmaschine gefilzt werden, bis sie die gewünschte Endgröße erreichen."])},
    "Felting_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Filzen wird das Material fester und dichter, was es haltbarer, wärmer und wasserabweisender macht."])},
    "Felting_density_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filzgrad"])},
    "Choose_how_much_to_felt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie stark sollen die Handschuhe gefilzt werden?"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Filzen"])},
    "Felt_a_little": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leicht (die Handschuhe werden 10% größer gestrickt)"])},
    "Felt_somewhat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittel (die Handschuhe werden 20% größer gestrickt)"])},
    "Felt_a_lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stark (die Handschuhe werden 30% größer gestrickt)"])},
    "Yarn_for_felting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geeignete Garne zum Filzen"])},
    "Yarn_for_felting_info_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prinzipiell lässt sich jedes Wollgarn, das nicht superwash-behandelt ist, filzen (z.B. Schurwolle oder Alpaka), das Endergebnis ist aber von Garn zu Garn verschieden. Auch die Maschenprobe spielt eine Rolle: Locker gestrickte Stücke lassen sich stärker filzen als fest gestrickte Stücke."])},
    "Yarn_for_felting_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Angaben \"Leicht\", \"Mittel\" und \"Stark\" sind deshalb Richtwerte für die fertigen Handschuhe. Genaue Angaben würden von der verwendeten Wolle und der eigenen Strickart abhängen."])},
    "Suggestion_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschlag:"])},
    "Yarn_for_felting_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir verwenden für gefilzte Handschuhe z.B. isländische Plötulopi (zweifädig verstrickt) mit einer Maschenprobe von 16 Maschen auf 10 cm."])},
    "Cuff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bündchen"])},
    "Cuff_felting_side_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das im Bündchen verwendete Rippenmuster schrumpft beim Filzen in der Länge noch stärker als der glatt rechts gestrickte Hauptteil. Um das auszugleichen, wird das Rippenbündchen im Vergleich zum Hauptteil besonders lang gestrickt."])},
    "Cuff_cast_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Schlagt ", _interpolate(_named("castOnStitchCount")), " Maschen auf vier Nadeln eines Nadelspiels an. Verwendet dabei die Nadelstärke, mit der ihr die vorher ermittelte Maschenprobe erreicht. Schließt zur Runde und strickt das Bündchen im Rippenmuster (", _interpolate(_named("cuffPatternRepCountHalf")), " M re, ", _interpolate(_named("cuffPatternRepCountHalf")), " M li im Wechsel), bis das Stück ", _interpolate(_named("calculatedCuffLength")), " cm lang ist."])},
    "Hand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hand"])},
    "Knit_a_round_and_increase": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt jetzt eine Runde re und nehmt dabei gleichmäßig verteilt ", _interpolate(_named("handCuffStitchCountDiff")), " Maschen zu. Jetzt sollten ", _interpolate(_named("handStitchCount")), " Maschen auf der Nadel liegen."])},
    "Knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt jetzt rechts in Runden, bis das Stück ab der ersten Reihe des Hauptteils, nach dem Bündchen, ", _interpolate(_named("beforeThumbLength")), " cm lang ist."])},
    "Make_sure_half_the_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Achtet darauf, dass die Maschen für den nächsten Schritt gleichmäßig auf den Nadeln verteilt werden (d.h. ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " und ", _interpolate(_named("stitchCountEachEvenNeedle")), " Maschen pro Nadel). Wenn ihr eine Rundstricknadel verwendet, markiert ihr die Stelle, an der Nadel 2 auf Nadel 3 treffen würde (d.h. nach ", _interpolate(_named("halfHandStitchCount")), " Maschen), mit einem Maschenmarkierer."])},
    "Right_mitten_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechter Handschuh:"])},
    "Right_mitten_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt alle Maschen von Nadel 1 und 2, und die ersten ", _interpolate(_named("thumbMarginStitchCount")), " Masche von Nadel 3. Strickt jetzt die nächsten ", _interpolate(_named("thumbOpeningStitchCount")), " Maschen mit einem Hilfsfaden. Legt diese Maschen wieder auf die linke Nadel und strickt sie noch einmal mit dem normalen Arbeitsfaden, sodass der Hilfsfaden fest eingestrickt ist. Strickt die Runde normal zu Ende."])},
    "Left_mitten_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linker Handschuh:"])},
    "Left_mitten_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt alle Maschen von Nadel 1 und strickt die Maschen von Nadel 2 bis ", _interpolate(_named("thumbMarginPlusThumbOpeningStitchCount")), " Maschen vor Ende der Nadel. Strickt die nächsten ", _interpolate(_named("thumbOpeningStitchCount")), " Maschen mit einem Hilfsfaden. Legt diese Maschen wieder auf die linke Masche und strickt sie noch einmal mit dem normalen Arbeitsfaden, sodass der Hilfsfaden fest eingestrickt ist. Strickt die Runde normal zu Ende."])},
    "Decrease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abnahmen"])},
    "Make_sure_again_half_the_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolliert hier noch einmal, dass die Maschen wie vorher auf den Nadeln verteilt sind: Die erste Hälfte auf Nadeln 1 und 2, die zweite Hälfte auf Nadeln 3 und 4."])},
    "Work_to_decreases_in_the_following_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strickt jetzt wie folgt die Abnahmen:"])},
    "First_round_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Runde (Abnahmerunde):"])},
    "Knit_one_stitch_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strickt 1 Masche re."])},
    "Decrease_round_description_first_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstNeedle")), " ", _interpolate(_named("conditionalAdditionalFirstStitch")), " Hebt 1 Masche ungestrickt wie zum rechts stricken ab. Strickt 1 Masche re und hebt die abgehobene Masche darüber. Strickt weiter bis zum Ende der Nadel."])},
    "Decrease_round_description_second_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("secondNeedle")), " Strickt bis ", _interpolate(_named("tipMarginStitchCountPlusTwo")), " Maschen vor Ende der Nadel. Strickt 2 Maschen re zusammen", _interpolate(_named("conditionalAdditionalLastStitch")), "."])},
    "_and_then_knit_x_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([", strickt ", _interpolate(_named("tipMarginStitchCount")), " Masche re"])},
    "Decrease_round_description_third_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("thirdNeedle")), " Wie Nadel 1."])},
    "Decrease_round_description_fourth_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fourthNeedle")), " Wie Nadel 2."])},
    "Knit_decrease_rounds_from_here_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt jetzt jede Runde mit Abnahmen, bis nur noch insgesamt ", _interpolate(_named("finalRowStitchCount")), " Maschen auf den Nadeln liegen. Schneidet den Faden ab und fädelt das Ende durch die übrigen Maschen."])},
    "Thumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daumen"])},
    "Thumb_description_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Entfernt jetzt vorsichtig den Hilfsfaden, den ihr für das Daumenloch eingestrickt habt. Legt die offenen Maschen über und unter dem Daumenloch auf zwei Nadeln aus dem Nadelspiel, d.h. ", _interpolate(_named("thumbOpeningStitchCountMinusOne")), " oberhalb des Lochs und ", _interpolate(_named("thumbOpeningStitchCount")), " unterhalb. Fasst zusätzlich auf beiden Seiten vor und nach diesen Maschen je eine weitere Masche aus der darunterliegenden Reihe auf (insgesamt 4 Maschen), um Löcher zu vermeiden. Jetzt sollten insgesamt ", _interpolate(_named("initialThumbStitchCount")), " Maschen auf den Nadeln liegen: ", _interpolate(_named("initialThumbStitchCountHalfFloor")), " oberhalb und ", _interpolate(_named("initialThumbStitchCountHalfCeil")), " unterhalb der Öffnung."])},
    "Thumb_first_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginnt die erste Runde an der nach innen gerichteten Seite der Handfläche, nicht am äußeren Rand. Strickt die Maschen, die ihr an den Seiten neu aufgefasst habt, verschränkt ab."])},
    "Thumb_second_round_description_side_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da ihr beide Daumen an der inneren Seite des Daumenlochs beginnt, strickt ihr beim rechten Handschuh zuerst die Maschen oberhalb des Daumenlochs, beim linken Handschuh dagegen die Maschen unterhalb des Daumenlochs."])},
    "Thumb_second_round_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nehmt in dieser Runde ", _interpolate(_named("stitchesKnitTogetherInSecondRow")), " Maschen ab, indem ihr die Maschen unterhalb des Daumenlochs wie folgt strickt: Strickt 2 Maschen re zusammen und strickt dann weiter, bis 2 Maschen unterhalb des Daumenlochs übrig bleiben. Hebt die nächste Masche wie zum re stricken ab, strickt die letzte Masche re und hebt die abgehobene Masche darüber. Strickt die Maschen oberhalb des Daumenlochs ohne Abnahmen. Jetzt sollten ", _interpolate(_named("thumbStitchCount")), " Maschen auf den Nadeln liegen."])},
    "Thumb_knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt jetzt in Runden, bis der Daumen ", _interpolate(_named("thumbLengthMinusThumbTiplength")), " cm misst."])},
    "Thumb_knit_decrease_rounds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ab jetzt nehmt ihr in jeder Runde Maschen ab, bis nur noch insgesamt ", _interpolate(_named("thumbFinalRowStitchCount")), " Maschen auf den Nadeln liegen. Das geht wie folgt:"])},
    "Thumb_decrease_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strickt beide Hälften des Daumens (jeweils die Hälfte der Maschen, ober- und unterhalb des Daumenlochs) auf dieselbe Weise: Hebt 1 M wie zum re stricken ab, strickt 1 M re, hebt die abgehobene Masche darüber. Strickt bis 2 M vor Ende der Hälfte und strickt 2 M zusammen."])},
    "Thumb_break_the_yarn_and_pull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schneidet den Faden ab, fädelt das Ende durch die verbleibenden Maschen und schließt so den Daumen."])},
    "Weave_in_all_ends_and_knit_the_other_mitten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vernäht alle Enden. Strickt den zweiten Handschuh gleich - befolgt hier aber die Angaben unter \"linker Handschuh\", wenn ihr zuerst den rechten Handschuh gestrickt habt."])},
    "Felting_description_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die fertigen Handschuhe können von Hand oder in der Waschmaschine gefilzt werden. Das Filzen von Hand gibt mehr Kontrolle über die finale Form und Größe, während das Ergebnis in der Waschmaschine je nach Maschine und verwendeter Wolle unterschiedlich ausfallen kann. Meistens ist das jedoch unproblematisch, da die Handschuhe im nassen Zustand noch gut gedehnt werden können, wenn sie zu klein geworden sind, oder erneut gefilzt werden können (ggf. sogar bei höherer Temperatur), wenn sie nach dem ersten Durchgang noch zu groß sind."])},
    "Felting_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhere Temperaturen, die Beigabe von Wollseife oder Waschmittel (Achtung: kein Weichspüler!) oder das Waschen mit anderen Kleidungsstücken oder Handtüchern begünstigen in der Regel den Filzprozess. Wenn ihr die Handschuhe mit anderen Stücken wascht, dürfen diese nicht fusseln."])},
    "Felting_a_little_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wascht die Handschuhe mit etwas Wollseife oder Waschmittel in der Waschmaschine. Wählt dabei ein Kurzprogramm (aber kein Woll- oder Handwäscheprogramm) mit Schleudergang und 40° Wassertemperatur."])},
    "Felting_somewhat_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wascht die Handschuhe mit etwas Wollseife oder Waschmittel in der Waschmaschine. Wählt dabei ein normales Waschprogramm (kein Woll- oder Handwäscheprogramm) mit Schleudergang und 40° Wassertemperatur."])},
    "Felting_a_lot_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wascht die Handschuhe mit etwas Wollseife oder Waschmittel in der Waschmaschine. Wählt dabei ein normales Waschprogramm (kein Woll- oder Handwäscheprogramm) mit Schleudergang und 60° Wassertemperatur."])},
    "v1_1_0": {
      "Cuff_cast_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Schlagt ", _interpolate(_named("castOnStitchCount")), " Maschen an und verteilt sie wie folgt auf die 4 Nadeln eures Nadelspiels: ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " und ", _interpolate(_named("stitchCountEachEvenNeedle")), " Maschen. Alternativ könnt ihr eine Rundstricknadel verwenden. Schließt zur Runde und strickt das Bündchen im Rippenmuster (", _interpolate(_named("cuffPatternRepCountHalf")), " M re, ", _interpolate(_named("cuffPatternRepCountHalf")), " M li im Wechsel), bis das Stück ab dem Maschenanschlag ", _interpolate(_named("calculatedCuffLength")), " cm lang ist."])},
      "Make_sure_half_the_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Achtet darauf, dass die Maschen für den nächsten Schritt gleichmäßig auf den Nadeln verteilt werden (d.h. Wie vorher ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " und ", _interpolate(_named("stitchCountEachEvenNeedle")), " Maschen pro Nadel). Wenn ihr eine Rundstricknadel verwendet, markiert ihr die Stelle, an der Nadel 2 auf Nadel 3 treffen würde (d.h. nach ", _interpolate(_named("halfHandStitchCount")), " Maschen), mit einem Maschenmarkierer."])},
      "Right_mitten_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt die ", _interpolate(_named("halfHandStitchCount")), " Maschen von Nadeln 1 und 2 (oder bis zum Maschenmarkierer). Strickt dann ", _interpolate(_named("thumbMarginStitchCount")), " Masche(n) von Nadel 3. Strickt die nächsten ", _interpolate(_named("thumbOpeningStitchCount")), " Maschen mit einem anderen, möglichst glatten Garn in ähnlicher Stärke. Legt diese Maschen wieder zurück auf die linke Nadel und strickt sie noch einmal mit dem normalen Arbeitsfaden, sodass der zweite Faden jetzt fest eingestrickt ist. Strickt die Runde zu Ende."])},
      "Left_mitten_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt die ersten ", _interpolate(_named("stitchCountUntilThumb")), " Maschen. Strickt dann die nächsten ", _interpolate(_named("thumbOpeningStitchCount")), " Maschen mit einem anderen, möglichst glatten Garn in ähnlicher Stärke. Legt diese Maschen wieder zurück auf die linke Nadel und strickt sie noch einmal mit dem normalen Arbeitsfaden, sodass der zweite Faden jetzt fest eingestrickt ist. Strickt jetzt die übrige(n) ", _interpolate(_named("thumbMarginStitchCount")), " Masche(n) von Nadel 2 (oder bis zum Maschenmarkierer). Strickt die Runde zu Ende."])},
      "Make_sure_again_half_the_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kontrolliert vor dem nächsten Schritt wieder, dass die Maschen gleichmäßig auf 4 Nadeln verteilt sind, d.h. ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " und ", _interpolate(_named("stitchCountEachEvenNeedle")), " Maschen pro Nadel. Wenn ihr die Handschuhe mit einer Rundstricknadel strickt, sollte der Maschenmarkierer weiterhin dort hängen, wo die zweite und die dritte Nadel aufeinandertreffen, d.h. nach ", _interpolate(_named("halfHandStitchCount")), " Maschen."])},
      "Thumb_first_round_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beginnt die erste Runde an der inneren Seite der Handfläche und strickt ", _interpolate(_named("thumbInitialHalfStitchCount")), " Maschen. Strickt dabei die zusätzlichen Maschen, die ihr aus den Seiten aufgenommen habt, verschränkt ab. Wenn ihr mit einer Rundstricknadel strickt, hängt jetzt einen Maschenmarkierer auf die Nadel. Strickt dann die zweite Hälfte der Maschen genauso."])},
      "Thumb_second_round_right_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt die ersten ", _interpolate(_named("thumbInitialUpperHalfStitchCount")), " Maschen. Strickt 2 M zusammen. Strickt die nächsten ", _interpolate(_named("thumbInitialLowerHalfStitchCountMinusFour")), " Maschen, d.h. bis 2 M vor Ende der Runde. Hebt 1 M ungestrickt ab, strickt die nächste M re und hebt die ungestrickte Masche darüber."])},
      "Thumb_second_round_left_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt 2 M zusammen. Strickt  ", _interpolate(_named("thumbInitialLowerHalfStitchCountMinusFour")), " Maschen, bis 2 M vor Ende der ersten Hälfte. Hebt 1 M ungestrickt ab, strickt die nächste M re und hebt die abgehobene Masche darüber. Strickt alle ", _interpolate(_named("thumbInitialUpperHalfStitchCount")), " Maschen der zweiten Hälfte, um die Runde zu beenden."])},
      "Thumb_knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jetzt liegen ", _interpolate(_named("thumbStitchCount")), " Maschen auf den Nadeln. Strickt in Runden, bis der Daumen ", _interpolate(_named("thumbLengthMinusThumbTiplength")), " misst."])},
      "Thumb_knit_decrease_rounds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nehmt jetzt in jeder Runde Maschen ab, bis nur noch ", _interpolate(_named("thumbFinalRowStitchCount")), " Maschen auf der Nadel liegen."])},
      "Thumb_decrease_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt 1 M ungestrickt ab, strickt 1 M re und hebt die abgehobene M darüber. Strickt weiter bis 2 M vor Ende der ersten Hälfte und strickt 2 M zusammen. Hebt 1 M ungestrickt ab, strickt 1 M re und hebt die abgehobene M darüber. Strickt weiter bis 2 M vor Ende der ersten Hälfte und strickt 2 M zusammen."])}
    },
    "pattern_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Handschuhe werden vom Rippenbündchen zu den Fingern gestrickt. Der Hauptteil wird glatt rechts gestrickt. Der Daumen wird mit einem Hilfsfaden vorbereitet und nachträglich eingefügt. Die Spitze wird mit regelmäßigen Abnahmen geformt."])}
  },
  "common3": {
    "Size_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größe"])},
    "Choose_a_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle eine Größe"])},
    "Print_pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anleitung ausdrucken"])},
    "This_browser_cannot_print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider kann die Anleitung in diesem Browser nicht gedruckt werden. Versuche es mit einem anderen Browser, z.B. Google Chrome."])},
    "Decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abnahmerunde:"])}
  },
  "beanie": {
    "Head_circumference_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopfumfang"])},
    "Head_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kopfumfang um die Stirn und den breitesten Teil des Hinterkopfes (cm)"])},
    "Type_in_head_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib den Kopfumfang ein"])},
    "Brim_height_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bündchenlänge"])},
    "Brim_height_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Länge des umgeklappten Bündchens (cm)"])},
    "Type_in_brim_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib die Bündchenlänge ein"])},
    "There_are_too_few_cast_on_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit dieser Maschenprobe werden nicht genug Maschen angeschlagen. Wähle eine feinere Maschenprobe oder eine größere Größe."])},
    "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptteil"])},
    "Ribbing_coverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länge des Rippenmusters"])},
    "Whole_beanie_ribbed_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die komplette Mütze"])},
    "Only_brim_ribbed_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur im Bündchen"])},
    "Decrease_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Abnahmen"])},
    "Even_decrease_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsichtbare Abnahmen"])},
    "Cross_decrease_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreuzabnahme"])},
    "Body_cast_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Schlagt ", _interpolate(_named("castOnStitchCount")), " Maschen auf eine Rundstricknadel oder das Nadelspiel an. Verwendet dabei die Nadelstärke, mit der ihr die angegebene Maschenprobe erreicht."])},
    "Body_cast_on_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der italienische Maschenanschlag eignet sich besonders gut für eine schöne Kante, der normale Kreuzanschlag funktioniert aber auch gut."])},
    "body_description_ribbed_start": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Schließt zur Runde und strickt das Bündchen im Rippenmuster (", _interpolate(_named("cuffPatternRepCountHalf")), " M re, ", _interpolate(_named("cuffPatternRepCountHalf")), " M li im Wechsel), bis es ", _interpolate(_named("straightCuffLength")), " cm lang ist."])},
    "body_description_stockinette_top_zero_fold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt jetzt ", _interpolate(_named("lengthBeforeDecreaseMinusStraightCuffLength")), " cm glatt rechts."])},
    "body_description_stockinette_top_nonzero_fold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt jetzt weitere ", _interpolate(_named("lengthBeforeDecreaseMinusStraightCuffLength")), " cm glatt rechts, bis das Stück ab dem Maschenanschlag ", _interpolate(_named("lengthBeforeDecrease")), " cm lang ist, oder ", _interpolate(_named("lengthBeforeDecreaseMinusFoldLength")), " cm mit einem umgeklappten Bündchen von  ", _interpolate(_named("foldLength")), " cm. Die zweite Längenangabe ist etwas genauer, besonders bei dicker Wolle, da der Umschlag selbst einen Teil der Gesamtlänge einnimmt."])},
    "body_description_whole_ribbed_nonzero_fold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Schließt zur Runde und strickt die Mütze im Rippenmuster (", _interpolate(_named("cuffPatternRepCountHalf")), " M re, ", _interpolate(_named("cuffPatternRepCountHalf")), " M li im Wechsel), bis das Stück ab dem Maschenanschlag ", _interpolate(_named("straightCuffLength")), " cm lang ist, oder ", _interpolate(_named("straightCuffLengthMinusFoldLength")), " cm mit einem umgeklappten Bündchen von ", _interpolate(_named("foldLength")), " cm. Die zweite Längenangabe ist etwas genauer, besonders bei dicker Wolle, da der Umschlag selbst einen Teil der Gesamtlänge einnimmt."])},
    "Decrease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abnahmen"])},
    "First_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Abnahmerunde:"])},
    "First_even_decrease_round_description_2x2_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*2 Maschen re stricken, 2 Maschen li zusammenstricken.**"])},
    "First_even_decrease_round_description_1x1_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Hebt 1 Masche wie zum rechts stricken ab, strickt 1 Masche re, hebt die abgehobene Masche über die gestrickte. Strickt 1 Masche re und 1 Masche li.**"])},
    "First_even_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Strickt 2 Maschen re, strickt 2 Maschen re zusammen.**"])},
    "Repeat_from_star_to_2star_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wiederholt * bis ** über die gesamte Runde. Jetzt sollten ", _interpolate(_named("castOnStitchCountMinusEvenlyDecreasedStitchesCount")), " Maschen auf der Nadel liegen."])},
    "Knit_x_rounds_without_decreases": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt ", _interpolate(_named("numberOfRowsBetweenDecreases")), " Runden ohne Abnahmen."])},
    "Second_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweite Abnahmerunde:"])},
    "Second_even_decrease_round_description_2x2_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Strick 2 Maschen re zusammen, strickt 1 Masche li.**"])},
    "Second_even_decrease_round_description_1x1_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Strick 2 Maschen re zusammen, strickt 1 Masche li.**"])},
    "Second_even_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Strick 2 Maschen re zusammen, strickt 1 Masche re.**"])},
    "Third_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dritte Abnahmerunde:"])},
    "Third_even_decrease_round_description_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Hebt 1 Masche wie zum re stricken ab, strickt 1 Masche re und hebt die abgehobene Masche darüber.**"])},
    "Third_even_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Strickt 2 Maschen re zusammen.**"])},
    "Final_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Abnahmerunde:"])},
    "Final_even_decrease_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Strickt 2 Maschen re zusammen.**"])},
    "Repeat_from_star_to_2star_with_side_note": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wiederholt * bis ** über die gesamte Runde. Jetzt sollten ", _interpolate(_named("castOnStitchCountMinusEvenlyDecreasedStitchesCount")), " Maschen auf der Nadel liegen", _interpolate(_named("conditionalLastStitchSideNote")), "."])},
    "the_last_stitch_is_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (die letzte Masche wird rechts gestrickt)"])},
    "Break_the_yarn_and_pull_weave_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schneidet den Faden ab, fädelt das Ende durch die verbleibenden Maschen und schließt so die Mütze. Vernäht alle Enden."])},
    "Cross_decrease_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In jeder Abnahmerunde werden an vier Stellen in der Mütze je 2 Maschen abgenommen (insgesamt 8 Maschen pro Runde). Markiert euch diese Stellen mit einem Maschenmarkierer."])},
    "First_round_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Runde (Abnahmerunde):"])},
    "Back_of_the_head_left_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinterkopf, linke Seite:"])},
    "Work_x_stitches_in_x_stitch_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt ", _interpolate(_named("numberOfStitchesBetweenDecreases")), " Maschen ", _interpolate(_named("typeOfStitch")), "."])},
    "stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["glatt rechts"])},
    "rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["im Rippenmuster"])},
    "Decrease_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abnahme:"])},
    "First_cross_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt 1 Masche wie zum re stricken ab, strickt 1 Masche re und hebt die abgehobene Masche darüber. Strickt 2 Maschen re zusammen."])},
    "First_cross_decrease_round_description_rib": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt 2 Maschen zusammen", _interpolate(_named("IntermediateRibStitches")), ". Hebt 1 Masche wie zum re stricken ab, strickt 1 Masche re und hebt die abgehobene Masche darüber."])},
    "purl_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", strickt 1 Masche li"])},
    "work_x_stitches_in_rib_stitch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([", strickt ", _interpolate(_named("crossDecreaseColumnStitchesMinusTwo")), " Maschen im Rippenmuster"])},
    "Left_side_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linke Seite:"])},
    "Work_x_stitches_in_x_stitch_and_then_decrease": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt ", _interpolate(_named("numberOfStitchesBetweenDecreases")), " M ", _interpolate(_named("typeOfStitch")), " und nehmt dann wie angegeben 2 Maschen ab."])},
    "Forehead_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stirn:"])},
    "Right_side_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechte Seite:"])},
    "Back_of_the_head_right_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinterkopf, rechts:"])},
    "Now_the_number_of_stitches_has_decreased_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jetzt habt ihr in dieser Runde 8 Maschen abgenommen und habt jetzt ", _interpolate(_named("castOnStitchCountMinusEight")), " Maschen auf der Nadel."])},
    "Knit_the_round_in_x_without_decreases": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt die Runde ", _interpolate(_named("typeOfStitch")), " ohne Abnahmen."])},
    "Knit_a_decrease_round_except_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strickt eine Abnahmerunde: Nehmt an denselben Stellen ab wie in der letzten Abnahmerunde, nur dass jetzt 2 Maschen weniger zwischen den Abnahmen liegen."])},
    "Repeat_the_last_two_rounds_x_times_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wiederholt die letzten zwei Runden noch ", _interpolate(_named("everyOtherRowDecreaseCountMinusOne")), " Mal, bis nur noch ", _interpolate(_named("stitchCountBeforeEveryRowDecreases")), " Maschen übrig sind."])},
    "Now_there_should_be_x_stitches_remaining": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jetzt sollten noch ", _interpolate(_named("stitchCountBeforeEveryRowDecreases")), " Maschen auf der Nadel liegen."])},
    "Work_a_decrease_in_every_round_until_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strickt ab jetzt jede Runde als Abnahmerunde, bis nur noch ", _interpolate(_named("crossDecreaseFinalRowStitchCount")), " Maschen übrig bleiben. Schneidet den Faden ab, fädelt das Ende durch alle verbleibenden Maschen und schließt so die Mütze. Vernäht alle Fäden."])},
    "pattern_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Mütze wird wahlweise glatt rechts oder mit Rippennmuster von unten nach oben mit einem umgeklappten Bündchen gestrickt. Regelmäßige Abnahmen am Ende verleihen ihr ein elegantes, sauberes Finish. Für die perfekte Passform berechnen wir die Mütze mit einem Umfang, der 10% unter dem tatsächlichen Kopfumfang liegt."])}
  },
  "common4": {
    "Design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])}
  },
  "navbar": {
    "Patterns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anleitungen"])},
    "Tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfsmittel und Rechner"])},
    "Sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
    "Sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausloggen"])},
    "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])}
  },
  "common_user_settings": {
    "First_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "Last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "New_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
    "Confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen"])},
    "E_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])}
  },
  "sign_in": {
    "Not_a_registered_user_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch nicht registriert?"])},
    "Sign_up_instead_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melde dich hier an."])},
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
    "Welcome_back_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen zurück!"])},
    "Forgot_password_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
    "Get_a_new_one_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])}
  },
  "sign_up": {
    "Already_a_registered_user_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du bereits registriert?"])},
    "Sign_in_instead_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dann logge dich hier ein."])},
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
    "Suceess_Your_account_has_been_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast dein Konto erfolgreich erstellt."])},
    "Your_free_trial_starts_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein kostenloses 14-tägiges Probeabo hat angefangen."])},
    "We_ve_sent_a_verification_email_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben dir eine Bestätigungs-E-Mail an die angegebene E-Mail-Adresse geschickt. Bitte überprüfe dein Postfach und klicke auf den Link in der E-Mail, um dein Konto zu aktivieren"])},
    "Need_help_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brauchst du Hilfe?"])},
    "Contact_our_support_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konaktiere unseren Kundendienst."])},
    "Create_a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Benutzer erstellen"])},
    "Knittable_can_send_me_marketing_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, ich würde gerne neue Strickideen erhalten und über die neuesten Designs und Rechner informiert werden."])},
    "I_accept_terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich akzeptiere die allgemeinen Geschäftsbedingungen von Knittable."])}
  },
  "verify_email": {
    "Verify_my_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die E-Mail-Adresse verifizieren"])},
    "Your_email_address_has_been_verified_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse erfolgreich verifiziert."])},
    "Sign_in_to_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logge dich ein, um fortzufarhen."])},
    "Click_here_to_resend_verification_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bestätigungs-E-Mail erneut senden"])},
    "Verification_email_has_been_resent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bestätigungs-E-Mail wurde erneut versendet."])}
  },
  "user_profile": {
    "Reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
    "Edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil bearbeiten"])},
    "Delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto löschen"])},
    "Are_you_sure_you_want_to_delete_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du sicher, dass du dein Konto unwiderruflich löschen willst?"])},
    "This_action_cannot_be_undone_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Löschvorgang kann nicht rückgängig gemacht werden."])},
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "User_Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzerprofil"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])}
  },
  "common5": {
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschicken"])}
  },
  "get_started": {
    "Your_knitting_design_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Strickhelfer"])},
    "Unleash_your_creativity_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lass deiner Kreativität freien Lauf und "])},
    "Unleash_your_creativity_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stricke "])},
    "Unleash_your_creativity_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["so, wie du es willst"])},
    "Knittable_is_your_interactive_tool_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittable ist ein interaktives Tool, das dir mit jedem beliebigen Garn perfekte Ergebnisse liefert. Du brauchst nur deine persönliche Maschenprobe und die gewünschte Größe, und Knittable errechnet dir die passende Anleitung."])},
    "Try_it_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probiere es aus!"])}
  },
  "how_does_it_work": {
    "How_does_it_work_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie funktioniert das?"])},
    "1_step_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnapp dir deine Lieblingswolle aus dem Regal"])},
    "2_step_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle eine Größe aus"])},
    "3_step_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Und leg los!"])},
    "1_step_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Knittable kannst du jedes beliebige Garn verwenden. Alles was du brauchst, ist deine eigene Maschenprobe."])},
    "2_step_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle eine voreingestellte Größe aus, oder gib deine individuellen Maße ein."])},
    "3_step_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ran an die Nadeln, und los geht’s!"])},
    "1_step_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine individuelle Maschenprobe ist der Schlüssel zum Erfolg: Mit dieser Angabe erhält dein Strickstück die gewünschte Passform und Textur."])},
    "2_step_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die angegebenen Maße werden verwendet, um die Proportionen des fertigen Kleidungsstücks zu berechnen. Bewegungsspielraum (e. positive/negative ease) oder ggf. Veränderungen durch Filzen werden dabei berücksichtigt."])},
    "3_step_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehn dich zurück, lass die Nadeln klappern und verwandle dein Garn in ein wunderschönes Kunstwerk."])}
  },
  "pricing": {
    "Free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenlos"])},
    "Monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatsabo"])},
    "Yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahresabo"])},
    "yearly_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17% Rabatt"])},
    "paid_yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro Monat - $55 pro Jahr"])},
    "Choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu diesem Plan wechseln"])},
    "Chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "Next_payment_due_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die nächste Zahlug ist am ", _interpolate(_named("nextBillDate")), " fällig"])},
    "Cancellation_effective_from": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Kündigung wird am ", _interpolate(_named("cancellationEffectiveDate")), " wirksam"])},
    "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zahlungsart anpassen"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "Payment_is_past_due_next_retry_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Zahlung ist überfällig. Nächster Versuch, den Betrag abzubuchen: ", _interpolate(_named("nextRetryDate"))])},
    "Subscription_expired_since": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dein Abonnement ist seit dem ", _interpolate(_named("nextBillDate")), " abgelaufen"])},
    "Choose_what_fits_you_best": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welches Abo passt zu dir?"])}
  },
  "our_patterns": {
    "Our_Patterns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Anleitungen"])},
    "Our_patterns_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittable gibt dir interaktive Strickanleitungen, die du genau an deine eigenen Vorstellungen anpassen kannst."])},
    "Our_patterns_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anleitungen passen für jedes Garn und jede Größe, von Babys zu Erwachsenen. Die schlichten Modelle sind ideale Projekte für Anfänger, die ein erstes Projekt suchen, und bieten erfahrenen StrickerInnen eine gute Grundlage für eigene Designideen."])}
  },
  "subscription": {
    "Change_or_cancel_at_any_time_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst dein Abonnement über dein Nutzerprofil jederzeit ändern oder kündigen."])},
    "Go_back_to_pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Anleitung"])}
  },
  "gauge_from_swatch": {
    "tool_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Rechner berechnet deine Maschenprobe, basiert auf der Maschen- und Reihenanzahl in deinem Probestück."])},
    "Swatch_dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Maße deiner Maschenprobe"])},
    "Height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhe"])},
    "Width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breite"])},
    "Number_of_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschenanzahl"])},
    "Number_of_rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenanzahl"])},
    "Height_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie hoch ist der gemessene Teil (cm)"])},
    "Width_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie breit ist der gemessene Teil (cm)"])},
    "Number_of_stitches_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Maschenanzahl im gemessenen Teil"])},
    "Number_of_rows_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzahl der Reihen im gemessenen Teil"])},
    "Height_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhe eingeben"])},
    "Width_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breite eingeben"])},
    "Number_of_stitches_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschenanzahl eingeben"])},
    "Number_of_rows_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenanzahl eingeben"])},
    "Swatch_dimensions_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messe deine Maschenprobe in der Höhe und der Breite, und zähle dann die Maschen und Reihen in dem Abschnitt, den du gemessen hast."])},
    "Swatch_dimensions_information_bubble_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Probe muss nicht 10 x 10 cm groß sein. Je größer die Probe, desto genauer wird aber das Ergebnis."])},
    "Your_knitting_gauge_is_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mit deiner Maschenprobe ergeben ", _interpolate(_named("stitchGauge")), " Maschen x ", _interpolate(_named("rowGauge")), " Reihen 10 x 10 cm."])}
  },
  "common6": {
    "Calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnen"])}
  },
  "welcome": {
    "Welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen bei Knittable"])},
    "You_have_x_left_of_your_free_trial": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du hast noch ", _interpolate(_named("remainingDays")), " Tag(e) deines kostenlosen Probeabos übrig."])},
    "View_subscription_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Abo-Möglichkeiten anzeigen"])}
  },
  "tracking_behavior": {
    "We_would_like_to_track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir verwenden Cookies und ähnliche Technologien, um anonyme Benutzerdaten zu Analysezwecken und zur besseren Benutzerfreundlichkeit zu sammeln. Ist das OK?"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])}
  },
  "pattern_form": {
    "A_new_version_is_available_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Anleitung wurde überarbeitet. Stricke dein angefangenes Projekt noch mit der alten Version fertig und stelle dann für das nächste Modell auf die neue Version um."])},
    "Upgrade_and_calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anleitung aktualisieren und neu berechnen"])}
  },
  "common7": {
    "Knit_calculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strickrechner"])},
    "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "Subscribe_to_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melde dich an, um fortzufahren"])},
    "Change_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschenproben-Umrechner"])}
  },
  "knit_calculator": {
    "tool_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Rechner errechnet anhand deiner Maschenprobe, wie viele Maschen oder Reihen du benötigst, um ein Stück in deiner gewünschten Länge und Breite zu stricken."])},
    "stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschen"])},
    "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihen"])},
    "Stitch_gauge_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Maschenzahl auf 10 cm"])},
    "Row_gauge_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Reihenzahl auf 10 cm"])},
    "Width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breite"])},
    "Width_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib die gewünschte Breite ein"])},
    "Width_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die gewünschte Breite (cm)"])},
    "Length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länge"])},
    "Length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib die gewünschte Länge ein"])},
    "Length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die gewünschte Länge (cm)"])},
    "Project_dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Projekt"])},
    "Project_dimensions_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Maschenprobe besteht aus Maschenbreite und Maschenhöhe. Die Maschenbreite bestimmt, wie viele Maschen du brauchst, um 10 cm Breite zu erreichen. Die Maschenhöhe bestimmt, wie viele Reihen du stricken musst, um 10 cm Länge zu erhalten."])},
    "Project_dimensions_information_bubble_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzahl der Maschen oder Reihen wird mithilfe der Maschenprobe und der gewünschten Größe des Strickstücks berechnet."])},
    "Pattern_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musterrapport"])},
    "Pattern_repetition_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutze diesen Abschnitt, wenn dein geplantes Strickstück einen Musterrapport enthält."])},
    "Pattern_repetition_information_bubble_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Extramaschen werden in der Berechnung mitgezählt, aber nicht wiederholt - das sind z.B. die Randmaschen, oder Maschen, die am Ende gestrickt werden, um das letzte Muster zu beenden. Wenn das Strickstück z.B. auf jeder Seite 5 Randmaschen benötigt, und zusätzlich eine Masche, um das Muster zu beenden, beträgt die Anzahl der Extramaschen 5 + 5 + 1 = 11 Maschen."])},
    "Pattern_repetition_information_bubble_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extramaschen werden oft bei Stücken benötigt, die in Hin- und Rückreihen gestrickt werden. Wenn das Stück in der Runde gestrickt wird, gibt es in den meisten Fällen keine Extramaschen."])},
    "Pattern_stitch_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschenanzahl"])},
    "Pattern_stitch_count_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Maschenzahl für das Muster eingeben"])},
    "Pattern_stitch_count_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Maschenzahl, die für einen Musterrapport benötigt wird."])},
    "Additional_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extramaschen"])},
    "Additional_stitches_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzahl der Extramaschen eingeben"])},
    "Additional_stitches_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Gesamtzahl der Extramaschen, um das Muster zu beenden."])},
    "Pattern_row_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenzahl"])},
    "Pattern_row_count_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Reihenzahl für das Muster eingeben"])},
    "Pattern_row_count_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Reihenzahl, die für einen Musterrapport benötigt wird"])},
    "Additional_rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extrareihen"])},
    "Additional_rows_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzahl der zusätzlichen Reihen eingeben"])},
    "Additional_rows_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Gesamtzahl der zusätzlichen Reihen, um das Muster zu beenden."])},
    "Stitches_equals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("stitchCount")), " Maschen ergeben ", _interpolate(_named("width")), " cm."])},
    "Rows_equal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("rowCount")), " Reihen ergeben ", _interpolate(_named("length")), " cm."])},
    "Stitches_equals_with_pattern": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("stitchCount")), " Maschen ergeben ", _interpolate(_named("width")), " cm (", _interpolate(_named("widthDifference")), " cm). Darin sind ", _interpolate(_named("patternRepetition")), " Musterwiederholungen und ", _interpolate(_named("patternAdditionalCount")), " Extramasche(n) enthalten."])},
    "Rows_equals_with_pattern": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("rowCount")), " Reihen ergeben ", _interpolate(_named("length")), " cm (", _interpolate(_named("lengthDifference")), " cm). Darin sind ", _interpolate(_named("patternRepetition")), " Musterwiederholungen und ", _interpolate(_named("patternAdditionalCount")), " Extrareihe(n) enthalten."])}
  },
  "gauge_converter": {
    "tool_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Rechner berechnet die Maschen- oder Reihenanzahl in einem Strickstück neu, um mit abweichender Maschenprobe dieselbe Länge und Breite zu erhalten. Das ist nützlich, wenn eine Anleitung mit anderem Garn und abweichender Maschenprobe gestrickt werden soll."])},
    "Current_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Abschnitt bezieht sich auf deine tatsächliche Maschenprobe, die du für dein Projekt verwenden möchtest."])},
    "Original_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Abschnitt bezieht sich auf die ursprüngliche Maschenprobe, die z.B. in der Anleitung verwendet wird, und die du auf deine Maschenprobe umrechnen möchtest."])},
    "Current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Maschenprobe"])},
    "Original": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original"])},
    "Number_of_stitches_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Maschenzahl, die du umrechnen möchtest"])},
    "Number_of_rows_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Reihenanzahl, die du umrechnen möchtest"])},
    "Stitch_gauge_current_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Maschenanzahl, die du für 10 cm benötigst."])},
    "Row_gauge_current_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Reihenanzahl, die du für 10 cm benötigst."])},
    "Stitch_gauge_original_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die laut Anleitung benötigte Maschenanzahl für 10 cm Breite."])},
    "Row_gauge_original_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die laut Anleitung benötigte Reihenanzahl für 10 cm Höhe."])},
    "Original_stitch_count_equals_cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die gewünschte Breite beträgt ", _interpolate(_named("width")), " cm."])},
    "Original_row_count_equals_cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die gewünschte Länge beträgt ", _interpolate(_named("length")), " cm."])},
    "Stitches_equals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ergibt ", _interpolate(_named("stitchCount")), " Maschen mit deiner Maschenprobe."])},
    "Rows_equal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ergibt ", _interpolate(_named("rowCount")), " Reihen mit deiner Maschenprobe."])}
  },
  "common_tools": {
    "Calculate_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was möchtest du umrechnen?"])},
    "Stitch_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschenprobe (Maschen)"])},
    "Stitch_gauge_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib die Anzahl der Maschen für 10 cm Breite ein"])},
    "Row_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschenprobe (Reihen)"])},
    "Row_gauge_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib die Anzahl der Reihen für 10 cm Länge ein"])},
    "Number_of_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschenanzahl"])},
    "Number_of_rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenanzahl"])},
    "Number_of_stitches_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib die Maschenanzahl ein"])},
    "Number_of_rows_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib die Reihenanzahl ein"])}
  },
  "forgot_password": {
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschicken"])},
    "New_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
    "A_new_password_has_successfully_been_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail verschickt! Klicke auf den Link in der E-Mail, um dein Passwort zurückzusetzen."])},
    "Password_successfully_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Passwort wurde zurückgesetzt."])}
  }
}