<template>
  <v-sheet :class="['text-sm-left', 'app-pricing-card', { chosen: isChosen }]">
    <pricing-card-header :title="title" :price="price" :subtitle="subtitle"> </pricing-card-header>

    <main-button
      v-if="!props.active"
      class="try-it-out-button"
      :dark="true"
      @click="router.push(composeRoute(locale, 'signup'))"
    >
      {{ $t("get_started.Try_it_out") }}
    </main-button>

    <pricing-card-content :numberOfEnabledFeatures="numberOfEnabledFeatures"></pricing-card-content>

    <template v-if="props.active">
      <subscription-buttons
        v-bind="props.subscription"
        :paddlePlanId="subscriptionPlanId"
        :email="props.email"
      ></subscription-buttons>
    </template>
  </v-sheet>
</template>

<script setup>
import { computed, defineProps, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { composeRoute } from "../lib/navigationUtils";
import MainButton from "./general/MainButton.vue";
import PricingCardHeader from "./PricingCardHeader.vue";
import PricingCardContent from "./PricingCardContent.vue";
import SubscriptionButtons from "./SubscriptionButtons.vue";
import { useLangStore } from "./../stores/lang";

const langStore = useLangStore();
const locale = langStore.getLocale();

const { t } = useI18n();

const router = useRouter();

let subscriptionPlanIdMonthly = process.env.VUE_APP_SUBSCRIPTION_PLAN_ID_MONTHLY;
let subscriptionPlanIdYearly = process.env.VUE_APP_SUBSCRIPTION_PLAN_ID_YEARLY;

const props = defineProps({
  active: {
    type: Boolean,
  },
  subscriptionType: {
    type: String,
    validator: (value) => ["free", "monthly", "yearly"].includes(value),
  },
  subscription: {
    type: Object,
  },
  email: {
    type: String,
  },
});

const title = ref(null);
const subtitle = ref("");
const price = ref(0);
const numberOfEnabledFeatures = ref(null);
const subscriptionPlanId = ref(null);

watch(locale, () => {
  initialiseCard();
});

onMounted(() => {
  initialiseCard();
});

const initialiseCard = () => {
  switch (props.subscriptionType) {
    case "free":
      title.value = t("pricing.Free");
      subtitle.value = "";
      price.value = 0;
      numberOfEnabledFeatures.value = 1;
      subscriptionPlanId.value = null;
      break;
    case "monthly":
      title.value = t("pricing.Monthly");
      subtitle.value = "";
      price.value = 5.5;
      numberOfEnabledFeatures.value = 3;
      subscriptionPlanId.value = subscriptionPlanIdMonthly;
      break;
    case "yearly":
      title.value = t("pricing.Yearly");
      subtitle.value = t("pricing.paid_yearly");
      price.value = 4.6;
      numberOfEnabledFeatures.value = 4;
      subscriptionPlanId.value = subscriptionPlanIdYearly;
      break;
  }
};

const isChosen = computed(() => {
  if (props.subscription == null) {
    return false;
  }
  if (props.subscription.planId == subscriptionPlanId.value) {
    return true;
  }
  return false;
});
</script>

<style scoped lang="scss">
@import "./../../scss/styles.scss";

.app-pricing-card {
  display: flex;
  padding: 5rem 3rem;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  border-radius: 7.5rem;
  border: 3px solid var(--knittable-lav-light);
  background: var(--knittable-isa);
}

.app-sm-and-down .app-pricing-card {
  border-radius: 5.5rem;
}

.app-pricing-card.chosen {
  border-color: var(--knittable-lav-darker);
  background-color: var(--knittable-lav-lighter);
}

.try-it-out-button {
  width: 100%;
}
</style>
