import axios from "../axios";

import { useAuthStore } from "../stores/auth";

export const saveCurrentProject = async (patternType, patternVersion, formData, bookmark) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  const params = {
    pattern_version: patternVersion,
    settings: formData,
    bookmark: bookmark,
  };

  await axios.post(`projects/${patternType}/current`, params, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

  return;
};

export const saveBookmark = async (patternType, bookmark) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  const params = {
    bookmark: bookmark,
  };

  await axios.post(`projects/${patternType}/current/bookmark`, params, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const loadCurrentProject = async (patternType) => {
  try {
    const authStore = useAuthStore();
    const authToken = authStore.authToken;

    const response = await axios.get(`projects/${patternType}/current`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });

    const returnObject = {
      settings: response.data.settings,
      bookmark: response.data.bookmark,
      patternVersion: response.data.pattern_version,
      createdAt: response.data.created_at,
      updatedAt: response.data.updated_at,
    };

    return returnObject;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    } else {
      throw error;
    }
  }
};
