import axios from "../axios";

import { useAuthStore } from "../stores/auth";

export const calculateKnit = async (
  entityGauge,
  dimension,
  patternEntityCount,
  patternAdditionalCount
) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  const params = {
    entity_gauge: entityGauge,
    dimension: dimension,
    pattern_entity_count: patternEntityCount,
    pattern_additional_count: patternAdditionalCount,
  };

  const response = await axios.get("/tools/knitcalculator", {
    params: params,
    headers: { Authorization: `Bearer ${authToken}` },
  });

  return [
    new ResultProperties(
      response.data.lower_entity_count,
      response.data.lower_dimension,
      response.data.lower_pattern_repetition,
      patternAdditionalCount
    ),
    new ResultProperties(
      response.data.higher_entity_count,
      response.data.higher_dimension,
      response.data.higher_pattern_repetition,
      patternAdditionalCount
    ),
  ];
};

export class ResultProperties {
  constructor(count, dimension = null, patternRepetition = null, patternAdditionalCount = null) {
    this.count = count;
    this.dimension = dimension;
    this.patternRepetition = patternRepetition;
    this.patternAdditionalCount = patternAdditionalCount;
  }
}
