export const passwordRules = [
  (value) => {
    if (value) return true;

    return "Password is required.";
  },
  (value) => {
    if (value?.length >= 8) return true;

    return "Password must be 8 characters or more.";
  },
];

export const passwordConfirmRules = (password) => [
  (value) => {
    if (value == password) return true;

    return "Passwords do not match.";
  },
];

export const emailRules = [
  (value) => {
    if (value) return true;

    return "E-mail is required.";
  },
  (value) => {
    if (/.+@.+\..+/.test(value)) return true;

    return "E-mail must be valid.";
  },
];

export const nameRules = [
  (value) => {
    if (value) return true;

    return "Name is required.";
  },
];
