<template>
  <v-row>
    <v-col cols="12">
      <get-started></get-started>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <pattern-catalog></pattern-catalog>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <how-does-it-work></how-does-it-work>
    </v-col>
  </v-row>
  <v-row v-if="authToken == null">
    <v-col cols="12">
      <pricing></pricing>
    </v-col>
  </v-row>
</template>

<script setup>
import { storeToRefs } from "pinia";

import GetStarted from "./../components/GetStarted.vue";
import HowDoesItWork from "./../components/HowDoesItWork.vue";
import PatternCatalog from "./../components/PatternCatalog.vue";
import Pricing from "./../components/Pricing.vue";
import { useAuthStore } from "../stores/auth";

const authStore = useAuthStore();
const { authToken } = storeToRefs(authStore);
</script>

<style>

.app-md-and-up button.app-navbar-icon-button {
  padding: 0 1rem 0 0;
}

.app-sm-and-down button.app-navbar-icon-button {
  padding: 0 0.5rem 0 0;
}

.app-front-page-sheet {
  border-radius: 8.75rem 8.75rem 0rem 0rem;
  padding-bottom: 15rem;
  margin-top: -10rem;
}

.app-sm-and-down .app-front-page-sheet {
  border-radius: 5rem 5rem 0rem 0rem;
}
</style>
