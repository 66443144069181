<template>
  <div v-if="isOpen" class="mobile-menu-overlay">
    <div cols="6" class="app-navbar-logo" @click="goTo('home')">
      <img class="logo" alt="logo" src="./../assets/knittable-white.svg" />
    </div>

    <button class="app-btn-icon close-button" @click="closeMenu">
      <v-icon icon="mdi-close" />
    </button>

    <ul class="mobile-menu">
      <li class="menu-item">
        <span>{{ $t("navbar.Tools") }}</span>
        <ul class="sub-menu">
          <li class="sub-menu-item" v-for="(tool, i) in props.toolList" :key="i">
            <button @click="goTo(tool.routeName)">{{ tool.title }}</button>
          </li>
        </ul>
      </li>

      <li class="menu-item">
        <span>{{ $t("navbar.Patterns") }}</span>
        <ul class="sub-menu">
          <li class="sub-menu-item" v-for="(pattern, i) in props.patternList" :key="i">
            <button @click="goTo(pattern.routeName)">
              {{ pattern.title }}
              <span v-if="pattern.routeName == 'sweater'">
                <beta-badge></beta-badge>
              </span>
            </button>
          </li>
        </ul>
      </li>

      <li
        class="menu-item subscribe"
        v-if="authToken && !props.isSubscribed"
        @click="goTo('subscription')"
      >
        {{ $t("navbar.Subscribe") }}
      </li>
      <li v-if="authToken" class="sign-in-out" @click="signOut">{{ $t("navbar.Sign_out") }}</li>

      <li v-else class="sign-in-out" @click="goTo('signin')">
        <span>{{ $t("navbar.Sign_in") }}</span>
      </li>
    </ul>
    <div class="line-1"></div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

import { composeRoute } from "../lib/navigationUtils";
import { useLangStore } from "./../stores/lang";
import { useAuthStore } from "../stores/auth";
import BetaBadge from "./BetaBadge.vue";

const langStore = useLangStore();
const locale = langStore.getLocale();

const authStore = useAuthStore();
const { authToken } = storeToRefs(authStore);

const router = useRouter();

const props = defineProps({
  modelValue: {
    type: Boolean,
  },
  patternList: {
    type: Array,
  },
  toolList: {
    type: Array,
  },
  isSubscribed: {
    type: Boolean,
  },
});
const emit = defineEmits(["update:modelValue"]);

const isOpen = ref(props.modelValue);

watch(
  () => props.modelValue,
  () => {
    isOpen.value = props.modelValue;
  },
  { deep: true }
);

const closeMenu = () => {
  emit("update:modelValue", false);
};

const goTo = (routeName) => {
  closeMenu();
  router.push(composeRoute(locale.value, routeName));
};

async function signOut() {
  closeMenu();
  authStore.signOut();
  router.push(composeRoute(locale.value, "signin"));
}
</script>

<style scoped lang="scss">
@import "./../../scss/styles.scss";

.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--knittable-lav-darker);
  display: flex;
  z-index: 10000;
  color: var(--knittable-isa);

  justify-content: center;
  align-items: center;
}

.close-button {
  cursor: pointer;
  background-color: var(--knittable-lav-darker);
  color: var(--knittable-isa);

  position: fixed;
  top: 1.75rem;
  right: 2.5rem;
}

.mobile-menu {
  height: 100%;
  padding-top: 11rem;
  padding-bottom: 10rem;
  list-style: none;
  display: grid;
  max-width: 80%;
}

.sub-menu {
  list-style: none;
}

.menu-item {
  @include subtitleLarge;
  color: var(--knittable-lav-medium);
  padding-bottom: 3rem;
}

.subscribe {
  color: var(--knittable-isa);
}

.sign-in-out {
  @include subtitleLarge;
}

.sub-menu-item button {
  @include paragraphLargeBold;
  color: var(--knittable-isa);
  margin-top: 1rem;
}

.logo {
  position: fixed;
  top: 2.5rem;
  left: 1.5rem;
  height: 1.4375rem;
}
</style>
