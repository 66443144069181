<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <h3 class="app-page-title">{{ $t("sign_in.Welcome_back_") }}</h3>
  <div class="app-central-container">
    <div class="app-central-card">
      <v-card-text>
        <v-form v-model="signInFormValid" @submit.prevent="signIn">
          <v-container>
            <v-row>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                :label="$t('common_user_settings.E_mail')"
                required
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                hint="At least 8 characters"
                :label="$t('common_user_settings.Password')"
                required
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-row>

            <main-button class="submit-btn" :dark="true" :disabled="!signInFormValid">
              {{ $t("sign_in.Submit") }}
            </main-button>
          </v-container>
        </v-form>
        <v-text-field
          v-model="signInErrorMessage"
          label="Error Message"
          :value="signInErrorMessage"
          readonly
          prepend-icon="mdi-alert-circle-outline"
          v-if="signInErrorMessage"
        ></v-text-field>
        <resend-verification-button
          v-if="resendVerificationOption"
          :email="email"
        ></resend-verification-button>
      </v-card-text>
      <div>
        {{ $t("sign_in.Not_a_registered_user_") }}
        <localized-link to="signup">{{ $t("sign_in.Sign_up_instead_") }}</localized-link>
      </div>
      <div>
        {{ $t("sign_in.Forgot_password_") }}
        <localized-link to="forgotpassword">{{ $t("sign_in.Get_a_new_one_") }}</localized-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { composeRoute } from "../lib/navigationUtils";
import { useAuthStore } from "../stores/auth";
import LocalizedLink from "../components/LocalizedLink.vue";
import MainButton from "../components/general/MainButton.vue";
import { useLangStore } from "./../stores/lang";
import { emailRules } from "./../lib/formRules.js";
import ResendVerificationButton from "../components/ResendVerificationButton.vue";

const authStore = useAuthStore();

const langStore = useLangStore();
const locale = langStore.getLocale();

const router = useRouter();
const route = useRoute();

const props = defineProps(["showDialog"]);

const signInFormValid = ref(false);
const email = ref("");
const password = ref("");
const showPassword = ref(false);

const signInErrorMessage = ref("");

const loading = ref(false);

const resendVerificationOption = ref(false);

async function signIn() {
  try {
    signInErrorMessage.value = "";

    loading.value = true;
    await authStore.signIn(email.value, password.value);

    router.push(composeRoute(locale.value, route.query.next ?? null));
  } catch (error) {
    signInErrorMessage.value = error.msg;
    if (error.response?.data.detail == "UNVERIFIED_ACCOUNT") {
      resendVerificationOption.value = true;
    }
  } finally {
    loading.value = false;
  }
}
</script>

<style scoped lang="scss">
@import "./../../scss/styles.scss";
p {
  margin-bottom: 1rem;
}

.sign-in-inputs {
  padding-bottom: 1rem;
}

.submit-btn {
  margin-top: 1.5rem;
}
</style>
