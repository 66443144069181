export default {
  "common": {
    "Socks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukat"])},
    "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitat"])},
    "Size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koko"])},
    "Choose_a_size_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kokokategoria"])},
    "Size_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokokategoria"])},
    "Child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lapsi"])},
    "Grown_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aikuinen"])},
    "Custom_tailored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kustomoitu"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuukautta"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vuotta"])},
    "Calculate_pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laskelmoi ohje"])},
    "Knitting_gauge_from_swatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuletiheys mallitilkusta"])},
    "Unisex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisex"])},
    "Man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mies"])},
    "Woman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nainen"])},
    "Advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisävalinnat"])},
    "Ribbing_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joustinneuleen tyyppi"])},
    "Single_rib_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksinkertainen (1o, 1n)"])},
    "Double_rib_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tupla (2o, 2n)"])}
  },
  "footer": {
    "Slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinun ideasi ovat neulottavia"])},
    "Knitting_calculations_can_be_time_consuming_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulematikka voi olla monimutkaista ja aikaavievää. Islanniksi on sanonta \"Drjúg er lykkjustundin\", joka tarkoittaa, että jokainen neulomiseen käytetty hetki on hyvin käytetty. Knittablen avulla voit minimoida valmisteluun menevän ajan ja maksimoida neulomisajan."])},
    "Supported_by_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittablea tukee:"])}
  },
  "knitting_gauge": {
    "Knitting_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuletiheys"])},
    "Stitch_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmukkamäärä"])},
    "Row_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerrosmäärä"])},
    "Knitting_gauge_explained_1_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuletiheys on yhdistelmä silmukkatiheyttä ja kerrostiheyttä. Silmukkatiheys määrittää, kuinka monta silmukkaa mahtuu 10 cm leveään sileään neuleeseen, ja kerrostiheys määrittää, kuinka monta kerrosta tarvitaan 10 cm pitkän osan neulomiseen."])},
    "Knitting_gauge_explained_2_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useimmissa langoissa on suositeltu neuletiheys tietylle puikkokoolle. Jos käsialasi on yleensä keskitasoa, voit kokeilla käyttää suositeltua tiheyttä ja puikkoja."])},
    "Knitting_gauge_explained_3_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos käsialasi on tiukkaa tai löyhää, voit käyttää ehdotettua tiheyttä vaihtamalla neulepuikkojen kokoa suurempaan tai pienempään. Huomaa, että parhaan tuloksen saavuttamiseksi on aina turvallisinta neuloa mallitilkku ennen projektin aloittamista neuletiheyden varmistamiseksi."])},
    "Stitch_gauge_explanation_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmukoiden määrä 10 cm leveydellä"])},
    "Stitch_gauge_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esim. 18 silmukkaa Léttlopi-langalla neulottuna"])},
    "Row_gauge_explanation_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerrosten määrä 10 cm pituudella"])},
    "Row_gauge_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esim. 24 kerrosta Léttlopi-langalla neulottuna"])},
    "Estimate_row_gauge_from_stitch_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvioi kerrostiheys silmukkatiheyden pohjalta"])}
  },
  "socks": {
    "Shoe_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kengänkoko"])},
    "Choose_a_shoe_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kengänkoko"])},
    "Ankle_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilkan ympärysmitta"])},
    "Ankle_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ympärysmitta nilkan yläpuolelta (cm)"])},
    "Type_in_ankle_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita mitta"])},
    "Foot_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jalan ympärysmitta"])},
    "Foot_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ympärysmitta jalkaterän ympäri (cm)"])},
    "Type_in_foot_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita mitta"])},
    "Sock_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukan korkeus"])},
    "Sock_height_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukan korkeus kantapäästä pohkeeseen (cm)"])},
    "Type_in_sock_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita korkeus"])},
    "Sock_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukan pituus"])},
    "Sock_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukan pituus kantapäästä varpaisiin (cm)"])},
    "Type_in_sock_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita pituus"])},
    "Leg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jalka"])},
    "Leg_paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo ", _interpolate(_named("anklePieceLength")), " cm sileää neuletta."])},
    "Heel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kantapää"])},
    "Heel_next_up_heel_decreases_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyt kantapää muotoillaan tekemällä kantapääkavennuksia molemmilla puolilla:"])},
    "Heel_first_decrease_row_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. kerros kantapääkavennuksia:"])},
    "Heel_second_row_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. kerros:"])},
    "Foot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jalkaterä"])},
    "Knit_the_first_two_rounds_the_following_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo seuraavat kaksi kierrosta seuraavasti:"])},
    "Knit_a_round_with_twisted_edge_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo yksi kierros varmistaen, että poimit silmukat reunasta ja neulot ne silmukoiden takareunojen läpi."])},
    "Knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo suljettuna neuleena, kunnes sukan pituus on ", _interpolate(_named("footLengthMinusTipLength")), " cm kantapäästä mitattuna."])},
    "Toe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kärki"])},
    "Work_to_decreases_in_the_following_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo kärkikavennukset seuraavasti:"])},
    "First_round_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. kierros (kavennuskierros):"])},
    "Decrease_round_description_first_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstNeedle")), " Neulo kunnes puikoilla on ", _interpolate(_named("tipMarginStitchCountPlusTwo")), " silmukkaa jäljellä. Neulo kaksi silmukkaa yhteen. ", _interpolate(_named("conditionalAdditionalLastStitch")), "."])},
    "_and_then_knit_x_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([", neulo ", _interpolate(_named("tipMarginStitchCount"))])},
    "Decrease_round_description_second_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("secondNeedle")), " ", _interpolate(_named("conditionalAdditionalFirstStitch")), "Nosta silmukka neulomatta, neulo yksi silmukka, vedä nostettu silmukka neulotun silmukan yli. Neulo loput silmukat."])},
    "Knit_one_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo 1o. "])},
    "Decrease_round_description_third_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("thirdNeedle")), " Sama kuin ensimmäisellä puikolla."])},
    "Decrease_round_description_fourth_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fourthNeedle")), " Sama kuin toisella puikolla."])},
    "Knit_decrease_rounds_from_here_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jatka kavennuskierrosten neulomista, kunnes jäljellä on ", _interpolate(_named("finalRowStitchCount")), " silmukkaa. Katkaise lanka ja vedä se loppujen silmukoiden läpi. Päättele langanpätkät."])},
    "Knit_the_other_sock_the_same_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo toinen sukka samalla tavalla."])},
    "v1_1_0": {
      "Leg_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Luo ", _interpolate(_named("castOnStitchCount")), " silmukkaa neljälle sukkapuikolle (", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " ja ", _interpolate(_named("stitchCountEachEvenNeedle")), " stitches) tai pyöröpuikoille. Neulo joustinneuletta suljettuna neuleena (neulo oikein ", _interpolate(_named("cuffPatternRepCountHalf")), ", neulo nurin ", _interpolate(_named("cuffPatternRepCountHalf")), ") kunnes työ on ", _interpolate(_named("cuffLength")), " cm pitkä."])},
      "Heel_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aloita nyt kantalapun neulominen. Neulo ", _interpolate(_named("heelflapStitchCountLeft")), " silmukkaa 1. puikolle, käännä työ ympäri, nosta ensimmäinen silmukka nurin neulomatta. Neulo kaikki ", _interpolate(_named("heelflapStitchCountLeftMinusOne")), " 1. puikon silmukat nurin ja aseta silmukkamerkki. Sitten neulo nurin ", _interpolate(_named("heelflapStitchCountRight")), " silmukkaa 4. puikolta 1. puikolle. Ensimmäisellä puikolla on nyt ", _interpolate(_named("heelflapStitchCount")), " silmukkaa, joka on kantalapun alku. Loput ", _interpolate(_named("instepStitchCount")), " silmukkaa muodostavat jalan päällisen."])},
      "Heel_paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jatka ", _interpolate(_named("heelflapStitchCount")), " silmukoiden neulomista tasossa sileänä neuleena, (neulo työn oikealla puolella oikein, nurjalla puolella nurin, nostaen aina ensimmäisen silmukan neulomatta) kunnes olet neulonut ", _interpolate(_named("heelFlapRowCount")), " kerrosta kantapäälappuun, silmukkamerkistä oikealle laskettuna. Nyt reunasilmukoita pitäisi olla ", _interpolate(_named("heelFlapEdgeStitchCount")), " kantalapun molemmilla puolilla ja seuraava kerros  alkaa työn oikealla puolella."])},
      "Heel_first_decrease_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nosta ensimmäinen silmukka oikein neulomatta, neulo oikein unnes saavutat silmukkamerkin. Neulo oikein ", _interpolate(_named("heelFlapCenterLeftMinusOne")), " silmukkaa. Nosta 1o neulomatta, neulo 1o, vedä nostettu silmukka neulotun silmukan yli ja käännä työ."])},
      "Heel_second_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nosta ensimmäinen silmukka nurin neulomatta, neulo nurin kunnes saavutat silmukkamerkin. Neulo nurin ", _interpolate(_named("heelFlapCenterRightMinusOne")), " silmukkaa. Neulo 2n yhteen ja käännä työ."])},
      "Heel_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toista näitä kahta kerrosta, kunnes kaikki kantalapun sivulla olevat silmukat on kavennettu pois."])},
      "Heel_paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nosta ensimmäinen silmukka neulomatta, neulo oikein ", _interpolate(_named("heelFlapCenterRightMinusOne")), " silmukkaa kunnes saavutat silmukkamerkin."])},
      "Foot_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Loput sukasta neulotaan suljettuna neuleena. Jaa kantapään silmukat takaisin kahdelle puikolle ja poimi kaikki ", _interpolate(_named("heelFlapEdgeStitchCount")), " reunan silmukat kantalapu molemmilta puolilta käyttäen näitä kahta puikkoa. Lisää yksi silmukka kummallekin puikolle poimimalla silmukka kantalapun alkupäästä (silmukka on kantalapun ensimmäisellä kierroksella). Kierroksen alku on nyt näiden kahden puikon välissä, kantapään keskellä."])},
      "Foot_paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nyt puikoilla pitäisi olla yhteensä ", _interpolate(_named("totalStitchCountBeforeHeelDecrease")), " silmukkaa (", _interpolate(_named("firstNeedleStitchCount")), ", ", _interpolate(_named("secondNeedleStitchCount")), ", ", _interpolate(_named("thirdNeedleStitchCount")), " ja ", _interpolate(_named("fourthNeedleStitchCount")), " silmukkaa). Jos käytät pyöröpuikkoa, aseta silmukkamerkit “puikkojen” vaihdoskohtiin."])},
      "Knit_a_round_with_decreases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo kaikki 1. puikon silmukat, kunnes puikolla on 2 silmukkaa jäljellä, neulo 2o yhteen. Neulo 2. ja 3. puikon silmukat. Nosta 4. Puikon ensimmäinen silmukka oikein neulomatta, neulo 1 silmukka ja vedä nostettu silmukka neulotun yli. Neulo loput puikon silmukoista."])},
      "Repeat_the_two_rounds_above_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toista toinen ja kolmas kierros ", _interpolate(_named("heelDecreaseCountMinusOne")), " kertaa kunnes puikoilla on yhteensä ", _interpolate(_named("footStitchCount")), " silmukkaa."])},
      "Make_sure_equal_number_of_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Varmista, että silmukat on jaettu tasan puikkojen kesken, tai ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " ja ", _interpolate(_named("stitchCountEachEvenNeedle")), " silmukkaa, ennen kuin aloitat kärkikavennukset. Jos käytät pyöröpuikkoja, varmista että silmukkamerkit ovat 1. ja 2. puikon vaihtumiskohdassa, sekä 3. ja 4. puikon vaihtumiskohdassa."])}
    },
    "pattern_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukat neulotaan joustinvarresta alaspäin 10% negatiivisella väljyydellä. Jalkaosa on sileää neuletta ja kantapää muotoillaan kantalapulla ja kantapääkavennuksilla sopivan istuvuuden saavuttamiseksi. Jalkaterä muotoillaan astettaisilla kavennuksilla siistin lopputuloksen saamiseksi."])}
  },
  "common2": {
    "First_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. kierros:"])},
    "Second_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. kierros:"])},
    "Second_and_third_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. ja 3. kierros:"])},
    "Second_to_fourth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2:sta 4:nteen kierrokseen:"])},
    "Third_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. kierros:"])},
    "Fourth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. kierros:"])},
    "Fifth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5.kierros:"])},
    "Sixth_and_seventh_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. ja 7. kierros:"])},
    "Eighth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. kierros:"])},
    "Ninth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. kierros:"])},
    "First_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. puikko:"])},
    "Second_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. puikko:"])},
    "Third_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. puikko:"])},
    "Fourth_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. puikko:"])},
    "Knit_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo."])},
    "Knit_a_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo kavennuskierros."])}
  }
}