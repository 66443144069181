<template>
  <div class="app-toggle-container">
    <label>{{ label }}</label>
    <div :class="['toggle-button', { small: props.small }]">
      <div :class="['toggle-option', { selected: selectedIndex == 0 }]" @click="toggleOption(0)">
        {{ options[0].key }}
      </div>
      <div id="border-between"></div>
      <div :class="['toggle-option', { selected: selectedIndex == 1 }]" @click="toggleOption(1)">
        {{ options[1].key }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";

const props = defineProps({
  options: Array,
  modelValue: Object,
  label: String,
  small: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits();

const selectedIndex = computed({
  get: () => {
    if (props.modelValue == null) {
      return null
    }
    
    return props.modelValue.value == props.options[0].value ? 0 : 1
  },
  set: (value) => {
    emit("update:modelValue", props.options[value]);
  },
});

const toggleOption = (newIndex) => {
  selectedIndex.value = newIndex;
};
</script>

<style lang="scss" scoped>
@import "./../../../scss/styles.scss";

.app-toggle-container {
  text-align: left;
}

label {
  @include paragraphLargeBold;

  color: var(--knittable-lav-darker);
}
.toggle-button {
  display: flex;
  cursor: pointer;
  border-radius: 0.75rem;
  border: 2px solid var(--knittable-lav-light);
  height: 5.25rem;
  color: var(--knittable-lav-darker);
  overflow: hidden;
}

.toggle-button.small {
  height: 3.75rem;
}

#border-between {
  height: 100%;
  width: 2px;
  background-color: var(--knittable-lav-light);
}

.toggle-option {
  @include subtitleSmall;
  flex: 1;
  text-align: center;
  height: 100%;
  display: grid;
  align-items: center;
  padding-left: 3px;
  padding-right: 3px;
}

.toggle-option:hover,
.toggle-option.selected:hover {
  background-color: var(--knittable-lav-darker);
  color: var(--knittable-isa);
}

.selected {
  background-color: var(--knittable-lav);
  color: var(--knittable-isa);
}
</style>
