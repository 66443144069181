<template>
  <header :class="['app-pattern-form-header', { disabled: !props.isSubscribedOrOnTrial }]">
    <div>
      {{ $t("knit_calculator.Pattern_repetition") }}
      <information-bubble v-bind:colorsInverted="true" position="bottom" width="medium">
        <p>{{ $t("knit_calculator.Pattern_repetition_information_bubble_1") }}</p>
        <p>{{ $t("knit_calculator.Pattern_repetition_information_bubble_2") }}</p>
        <p>{{ $t("knit_calculator.Pattern_repetition_information_bubble_3") }}</p>
      </information-bubble>
    </div>

    <tooltip v-if="!props.isSubscribedOrOnTrial" position="left">
      <template v-slot:content>
        <v-icon icon="mdi-lock" />
      </template>
      <template v-slot:tooltip>
        <main-button :dark="true" @click="emitGoToSubscription" class="subscribe-button">
          {{ $t("common7.Subscribe_to_enable") }}</main-button
        >
      </template>
    </tooltip>
  </header>

  <div v-if="props.useStitches" class="app-pattern-form-side-by-side-container">
    <input-field
      class="app-pattern-form-input-field"
      v-model="internalRepetitionCount"
      :label="$t('knit_calculator.Pattern_stitch_count')"
      :description="$t('knit_calculator.Pattern_stitch_count_description')"
      :placeholder="$t('knit_calculator.Pattern_stitch_count_placeholder')"
      :small="true"
      :step="1"
      :disabled="!props.isSubscribedOrOnTrial"
    ></input-field>

    <v-spacer></v-spacer>

    <input-field
      class="app-pattern-form-input-field"
      v-model="internalAdditionalCount"
      :label="$t('knit_calculator.Additional_stitches')"
      :description="$t('knit_calculator.Additional_stitches_description')"
      :placeholder="$t('knit_calculator.Additional_stitches_placeholder')"
      :small="true"
      :step="1"
      :disabled="!props.isSubscribedOrOnTrial"
      :allow-zero="true"
    >
    </input-field>
  </div>

  <div v-else class="app-pattern-form-side-by-side-container">
    <input-field
      class="app-pattern-form-input-field"
      v-model="internalRepetitionCount"
      :label="$t('knit_calculator.Pattern_row_count')"
      :description="$t('knit_calculator.Pattern_row_count_description')"
      :placeholder="$t('knit_calculator.Pattern_row_count_placeholder')"
      :small="true"
      :step="1"
      :disabled="!props.isSubscribedOrOnTrial"
    ></input-field>

    <v-spacer></v-spacer>

    <input-field
      class="app-pattern-form-input-field"
      v-model="internalAdditionalCount"
      :label="$t('knit_calculator.Additional_rows')"
      :description="$t('knit_calculator.Additional_rows_description')"
      :placeholder="$t('knit_calculator.Additional_rows_placeholder')"
      :small="true"
      :step="1"
      :allow-zero="true"
      :disabled="!props.isSubscribedOrOnTrial"
    >
    </input-field>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

import InformationBubble from "../InformationBubble.vue";
import InputField from "./../general/InputField.vue";
import MainButton from "../general/MainButton.vue";
import Tooltip from "../Tooltip.vue";

const emit = defineEmits([
  "go-to-subscription",
  "update:patternRepetitionCount",
  "update:patternAdditionalCount",
]);

const props = defineProps({
  patternRepetitionCount: Number,
  patternAdditionalCount: Number,
  useStitches: Boolean,
  isSubscribedOrOnTrial: {
    type: Boolean,
    default: false,
  },
});

const internalRepetitionCount = ref(props.patternRepetitionCount);
const internalAdditionalCount = ref(props.patternAdditionalCount);

watch(
  () => props.patternRepetitionCount,
  (value) => {
    internalRepetitionCount.value = value;
  }
);

watch(
  () => props.patternAdditionalCount,
  (value) => {
    internalAdditionalCount.value = value;
  }
);

watch(internalRepetitionCount, (value) => {
  emit("update:patternRepetitionCount", value);
});

watch(internalAdditionalCount, (value) => {
  emit("update:patternAdditionalCount", value);
});

const emitGoToSubscription = () => {
  emit("go-to-subscription");
};
</script>

<style lang="scss" scoped>
@import "./../../../scss/form.scss";

.overlay-parent {
  position: relative;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.app-pattern-form-header {
  display: flex;
  justify-content: space-between;
}

.subscribe-button {
  width: 12rem;
}
</style>
