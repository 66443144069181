const crypto = require("crypto");

export const setConsentInLocalStorage = (canTrack) => {
  const savedAt = new Date();

  const consentData = { value: canTrack, savedAt: savedAt.toISOString() };

  localStorage.setItem("trackingConsent", JSON.stringify(consentData));
  return;
};

export const getConsentFromLocalStorage = () => {
  const storedData = localStorage.getItem("trackingConsent");
  if (!storedData) {
    return [null, null];
  }
  const consentData = JSON.parse(storedData);
  return [consentData.value, consentData.savedAt];
};

export const anonymiseUser = (userId) => {
  // Returns a hashed user id for anonymisation.
  // Matches the method anonymise_user in the backend.
  const hash = crypto.createHash("sha1");
  hash.update(userId.toString());
  const hashedUserId = hash.digest("hex");
  return hashedUserId;
};
