<template>
  <v-sheet class="app-get-started-sheet app-front-page-sheet">
    <v-row class="app-get-started-row">
      <v-col cols="12" sm="12" md="6" class="pa-0">
        <div class="app-get-started-text-container">
          <div class="app-get-started-super-title">
            {{ $t("get_started.Your_knitting_design_tool") }}
          </div>
          <div class="app-get-started-title">
            <span class="app-get-started-title-1">{{
              $t("get_started.Unleash_your_creativity_1")
            }}</span>
            <span class="app-get-started-title-2">
              {{ $t("get_started.Unleash_your_creativity_2") }}
            </span>
            <span class="app-get-started-title-3">
              {{ $t("get_started.Unleash_your_creativity_3") }}
            </span>
          </div>
          <div class="app-get-started-button-container">
            <v-btn v-if="authToken == null" :to="`/${locale}/signup`" class="app-button">
              {{ $t("get_started.Try_it_out") }}
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <div class="app-image-container">
          <div id="app-img-woman-knitting" class="app-front-page-img-box"></div>
          <div id="app-shadow-woman-knitting" class="app-front-page-img-box"></div>
        </div>
      </v-col>
    </v-row>
    <v-row class="app-get-started-short-description-row">
      <v-col cols="12" sm="12" md="6"> </v-col>
      <v-col cols="12" sm="12" md="6">
        <h2 class="app-get-started-short-description">
          {{ $t("get_started.Knittable_is_your_interactive_tool_") }}
        </h2>
      </v-col>
    </v-row>
    <v-spacer />
  </v-sheet>
</template>

<script setup>
import { storeToRefs } from "pinia";

import { useAuthStore } from "../stores/auth";
import { useLangStore } from "./../stores/lang";

const langStore = useLangStore();
const locale = langStore.getLocale();

const authStore = useAuthStore();
const { authToken } = storeToRefs(authStore);
</script>

<style scoped>

.app-get-started-sheet {
  padding-top: 10rem;
  background-color: var(--knittable-isa);
}

.app-get-started-text-container {
  margin-left: 10%;
  margin-right: 10%;
  text-align: left;
}

.app-sm-and-down .app-get-started-text-container {
  margin-top: 10%;
}

.app-md-and-up .app-get-started-text-container {
  padding-top: 14rem;
}

.app-get-started-super-title {
  color: var(--knittable-lav-darker);

  font-feature-settings: "clig" off, "liga" off;
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
  padding-bottom: 1rem;
}

.app-front-page-img-box {
  /* width: 90vw; */
  /* width: 40rem; */
  /* height: 46.194rem; */
  border-radius: 20%;
  width: 85%;
  /* height: 90%; */
  aspect-ratio: 1;
}

#app-shadow-woman-knitting {
  background: var(--knittable-yama);
  z-index: 0;
}

#app-img-woman-knitting {
  background: url("./../assets/knitting_hands.jpg"), lightgray 50% / cover no-repeat;
  background-size: cover;
  position: absolute;
  top: -1.25rem;
  left: 1.25rem;
  z-index: 1;

  background-position: center center;
}

.app-image-container {
  position: relative;
  margin-top: 3.5rem;
}

.app-get-started-title {
  font-family: Philosopher;
  font-size: 5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-align: left;
}

.app-sm-and-down .app-get-started-title {
  font-size: 3rem;
}

.app-get-started-title-1 {
  color: var(--knittable-lav-darker);
}

.app-get-started-title-2 {
  color: var(--knittable-ama);
}

.app-get-started-title-3 {
  color: var(--knittable-yama);
}

.app-get-started-button-container {
  text-align: right;
  margin-top: 2rem;

  .v-btn {
    background: var(--knittable-lav-darker);
    color: var(--knittable-isa);
  }
  top: -1.25rem;
  left: 1.25rem;
  z-index: 1;

  background-position: center center;
}

.app-sm-and-down .app-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-row .app-get-started-short-description-row {
  background: url("./../assets/line_lav.svg") no-repeat,
    url("./../assets/line_yellow.svg") no-repeat, 
    url("./../assets/line_red.svg") no-repeat,
    url("./../assets/line_green.svg") no-repeat;
  background-size: cover;
}

.app-md-and-up .v-row .app-get-started-short-description-row {
  margin-top: 12rem;
  margin-bottom: 12rem;
  padding-right: 11rem;
  text-align: left;
  height: 377px;
}

.app-sm-and-down .v-row .app-get-started-short-description-row {
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

h2.app-get-started-short-description {
  color: var(--knittable-lav-darker);

  font-family: Philosopher;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}

.app-sm-and-down h2.app-get-started-short-description {
  font-size: 1.75rem;
  padding-left: 5%;
  padding-right: 5%;
}
</style>
