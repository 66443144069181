<template>
  <ul class="app-pricing-card-content">
    <li v-for="(text, index) in items" :key="index" :class="itemStyles(index)">{{ $t(text) }}</li>
  </ul>
</template>

<script setup>
const props = defineProps({
  numberOfEnabledFeatures: Number,
});

const items = [
  "pricing.Knitting_tools",
  "pricing.Pattern_add_on_for_tools",
  "pricing.Interactive_knitting_patterns",
  "pricing.yearly_discount",
];

const itemStyles = (index) => {
  return index >= props.numberOfEnabledFeatures
    ? "app-pricing-card-content-disabled"
    : "app-pricing-card-content-enabled";
};
</script>

<style>
.app-pricing-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  color: var(--knittable-black);

  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 160%;

  list-style-position: outside;
  list-style-image: url("./../assets/ellipse-lav.svg");

  padding-left: 1.25rem;
}

.app-pricing-card-content li {
  padding-left: 0.75rem;
}
.app-pricing-card-content .app-pricing-card-content-disabled {
  color: var(--knittable-grey-dark);
  padding-left: 0.75rem;
}
</style>
