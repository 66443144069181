<template>
  <h3 class="app-page-title">{{ $t("common.Knitting_gauge_from_swatch") }}</h3>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <v-container class="app-pattern-form-container" fluid>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="6" class="app-pattern-form-col">
        <v-form class="app-pattern-form" @submit.prevent="Calculate(true)">
          <div class="app-form-description">{{ $t("gauge_from_swatch.tool_description") }}</div>
          <div class="app-pattern-form-section">
            <header class="app-pattern-form-header">
              <div>
                {{ $t("gauge_from_swatch.Swatch_dimensions") }}
              </div>
              <information-bubble v-bind:colorsInverted="true" position="left" width="medium">
                <p>{{ $t("gauge_from_swatch.Swatch_dimensions_information_bubble_1") }}</p>
                <p>{{ $t("gauge_from_swatch.Swatch_dimensions_information_bubble_2") }}</p>
              </information-bubble>
            </header>

            <div class="app-pattern-form-side-by-side-container">
              <input-field
                class="app-pattern-form-input-field"
                v-model="formData.stitchCount"
                :label="$t('common_tools.Number_of_stitches')"
                :description="$t('gauge_from_swatch.Number_of_stitches_description')"
                :placeholder="$t('common_tools.Number_of_stitches_placeholder')"
                :small="true"
                :step="1"
              ></input-field>
              <v-spacer></v-spacer>
              <input-field
                class="app-pattern-form-input-field"
                v-model="formData.width"
                :label="$t('gauge_from_swatch.Width')"
                :description="$t('gauge_from_swatch.Width_description')"
                :placeholder="$t('gauge_from_swatch.Width_placeholder')"
                :small="true"
              ></input-field>
            </div>
            <checkbox-field v-model="formData.estimateRowGaugeEnabled">{{
              $t("knitting_gauge.Estimate_row_gauge_from_stitch_gauge")
            }}</checkbox-field>
            <div
              v-if="!formData.estimateRowGaugeEnabled"
              class="app-pattern-form-side-by-side-container"
            >
              <input-field
                class="app-pattern-form-input-field"
                v-model="formData.rowCount"
                :label="$t('common_tools.Number_of_rows')"
                :description="$t('gauge_from_swatch.Number_of_rows_description')"
                :placeholder="$t('common_tools.Number_of_rows_placeholder')"
                :small="true"
                :step="1"
              ></input-field>
              <v-spacer></v-spacer>
              <input-field
                class="app-pattern-form-input-field"
                v-model="formData.height"
                :label="$t('gauge_from_swatch.Height')"
                :description="$t('gauge_from_swatch.Height_description')"
                :placeholder="$t('gauge_from_swatch.Height_placeholder')"
                :small="true"
              ></input-field>
            </div>
          </div>
          <pattern-form-button :label="$t('common6.Calculate')" :disabled="!buttonIsEnabled">
          </pattern-form-button>
        </v-form>
      </v-col>
      <v-col cols="12" sm="12" md="6" v-if="showResults" :class="[{ disabled: formHasChanges }]">
        <p class="app-tool-result">
          {{
            $t("gauge_from_swatch.Your_knitting_gauge_is_", {
              stitchGauge: roundToBase(stitchGauge10, 0.5) || "?",
              rowGauge: roundToBase(rowGauge10, 0.5) || "?",
            })
          }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";

import CheckboxField from "../../components/general/CheckboxField.vue";
import InformationBubble from "../../components/InformationBubble.vue";
import InputField from "./../../components/general/InputField.vue";
import PatternFormButton from "./../../components/patterns/PatternFormButton.vue";
import { loadCurrentSettings, saveCurrentSettings } from "./../../services/toolSettings";
import { roundToBase, getRowGaugeFromStitchGauge } from "../../lib/calculationUtils";

const toolType = "gaugefromswatch";
const toolVersion = "1.0.0";

const formData = ref({
  width: null,
  stitchCount: null,
  height: null,
  rowCount: null,
  estimateRowGaugeEnabled: true,
});

const stitchGauge10 = ref(null);
const rowGauge10 = ref(null);

const showResults = ref(false);
const formHasChanges = ref(false);
const loading = ref(false);

const formIsValid = computed(() => {
  const { width, stitchCount, estimateRowGaugeEnabled, height, rowCount } = formData.value;

  if (width === null || stitchCount === null) {
    return false;
  }

  return estimateRowGaugeEnabled || (height !== null && rowCount !== null);
});

watch(
  formData,
  () => {
    formHasChanges.value = true;
  },
  { deep: true }
);

onMounted(async () => {
  try {
    const loadedForm = await loadCurrentSettings(toolType, toolVersion);
    if (loadedForm != null) {
      formData.value = loadedForm;
      Calculate(false);
    }
  } catch (error) {
    alert(error);
  }
});

const Calculate = async (saveSettings) => {
  stitchGauge10.value = (10 * formData.value.stitchCount) / formData.value.width;
  if (formData.value.estimateRowGaugeEnabled) {
    rowGauge10.value = getRowGaugeFromStitchGauge(stitchGauge10.value);
  } else {
    rowGauge10.value = (10 * formData.value.rowCount) / formData.value.height;
  }
  
  try {
    loading.value = true;
    if (saveSettings) {
      await saveCurrentSettings(toolType, toolVersion, formData.value);
    }
  } catch (error) {
    alert(error);
  } finally {
    // wait because otherwise this completes befere watch(formData) on mounted has chance to fire
    await new Promise(resolve => setTimeout(resolve, 100));
    formHasChanges.value = false;
    showResults.value = true;
    loading.value = false;
  }
};

const buttonIsEnabled = computed(() => {
  return formIsValid.value && formHasChanges.value;
});
</script>

<style lang="scss" scoped>
@import "./../../../scss/tool.scss";
@import "./../../../scss/toolResults.scss";
@import "./../../../scss/form.scss";
</style>
