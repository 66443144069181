<template>
  <div class="button-container" v-if="props.planId === props.paddlePlanId">
    <template v-if="subscriptionIsActive()">
      <main-button @click="updateSubscription()"> {{ $t("pricing.Update") }}</main-button>
      <main-button @click="cancelSubscription()"> {{ $t("pricing.Cancel") }} </main-button>
      <div>
        <small>{{
          $t("pricing.Next_payment_due_by", { nextBillDate: props.nextBillDate || "?" })
        }}</small>
      </div>
    </template>
    <template v-else-if="subscriptionIsActiveButCancelled()">
      <main-button :disabled="true">{{ $t("pricing.Chosen") }}</main-button>
      <div>
        <small>{{
          $t("pricing.Cancellation_effective_from", {
            cancellationEffectiveDate: props.cancellationEffectiveDate || "?",
          })
        }}</small>
      </div>
    </template>
    <template v-else-if="subscriptionIsPastDue()">
      <main-button @click="updateSubscription()">{{ $t("pricing.Update") }}</main-button>
      <main-button @click="cancelSubscription()">{{ $t("pricing.Cancel") }}</main-button>
      <div v-if="props.status == 'past_due'">
        <small class="app-error-plain-text">{{
          $t("pricing.Payment_is_past_due_next_retry_at", {
            nextRetryDate: props.nextRetryDate || "?",
          })
        }}</small>
      </div>
      <div v-else-if="props.status == 'active'">
        <small class="app-error-plain-text">{{
          $t("pricing.Subscription_expired_since", { nextBillDate: props.nextBillDate || "?" })
        }}</small>

      </div>
      <div v-else-if="props.status == 'paused'">
        <small class="app-error-plain-text">{{ $t("pricing.Subscription_paused") }}</small>
      </div>
    </template>
  </div>
  <main-button
    v-else-if="props.planId == null && props.paddlePlanId != null"
    @click="openCheckout()"
  >
    {{ $t("pricing.Choose") }}
  </main-button>
</template>

<script setup>
import MainButton from "./general/MainButton.vue";

const props = defineProps({
  planId: {
    type: String,
  },
  paddlePlanId: {
    type: String,
  },
  nextBillDate: {
    type: String,
  },
  nextRetryDate: {
    type: String,
  },
  email: {
    type: String,
  },
  cancelUrl: {
    type: String,
  },
  updateUrl: {
    type: String,
  },
  status: {
    type: String,
  },
  cancellationEffectiveDate: {
    type: String,
  },
});

const currentUtc = new Date().toISOString();

function subscriptionIsActive() {
  return (
    (props.status == "trialing" || props.status == "active") && currentUtc < props.nextBillDate
  );
}

function subscriptionIsActiveButCancelled() {
  return props.status == "deleted" && currentUtc < props.cancellationEffectiveDate;
}

function subscriptionIsPastDue() {
  return (
    props.status == "past_due" ||
    (props.status == "active" && currentUtc > props.nextBillDate) ||
    props.status == "paused"
  );
}

function openCheckout() {
  Paddle.Checkout.open({
    product: props.paddlePlanId,
    email: props.email,
    passthrough: `{"email": "${props.email}"}`,
  });
}

function cancelSubscription() {
  Paddle.Checkout.open({
    override: props.cancelUrl,
  });
}

function updateSubscription() {
  Paddle.Checkout.open({
    override: props.updateUrl,
  });
}
</script>

<style scoped>
.button-container .main-button {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  flex-shrink: 0;
}
.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
