import axios from "../axios";

import { useAuthStore } from "../stores/auth";

export const saveUserTrackingPreference = async (canTrack) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  const params = {
    can_track: canTrack,
  };
  await axios.post(`usertrackingpreferences/me`, params, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

  return;
};

export const loadUserTrackingPreference = async () => {
  try {
    const authStore = useAuthStore();
    const authToken = authStore.authToken;

    const response = await axios.get(`usertrackingpreferences/me`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
    const savedAt = response.data.updated_at ?? response.data.created_at;

  return response.data.can_track, savedAt;
  }
  catch (error) {
    if (error.response && error.response.status === 404) {
      return [null, null];
    } else {
      throw error;
    }
  }
};
