<template>
  <div class="app-information-bubble">
    <tooltip v-bind="$props">
      <template v-slot:content>
        <font-awesome-icon :icon="['fas', 'info-circle']" />
      </template>
      <template v-slot:tooltip>
        <div class="app-information-bubble-content">
          <slot></slot>
        </div>
      </template>
    </tooltip>
  </div>
</template>

<script>
import Tooltip from "./Tooltip.vue";

export default {
  components: {
    Tooltip,
  },
  props: ["colorsInverted", "position", "width"],
};
</script>

<style lang="scss" scoped>
@import "./../../scss/styles.scss";
.app-information-bubble {
  display: inline;
}

.app-information-bubble-content {
  @include paragraphMediumRegular;

  display:grid;
  gap: 1rem;
}
</style>
