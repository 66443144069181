<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <div>
    <div class="app-pattern-toolbar">
      <button @click="saveAsPdf">
        <v-icon icon="mdi-pdf-box" />
      </button>
    </div>
    <section>
      <div class="pdf-header" ref="pdfHeader" v-if="showHeader">
        <h4>{{ props.title }}</h4>

        <h6>{{ $t("common.General") }}</h6>

        <div class="pdf-header-content">
          <div class="pattern-description" v-if="props.description">{{ props.description }}</div>
          <v-row>
            <v-col v-for="(property, index) in props.properties" :key="index" cols="6">
              <div>
                <strong>{{ property.label }}</strong
                >: {{ property.value }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <strong>{{ $t("common.Knitting_gauge") }}:</strong> {{ props.stitchGauge }}
              {{ $t("common.stitches") }} x {{ props.rowGauge }} {{ $t("common.rows") }} = 10 x 10
              cm
            </v-col>
          </v-row>

          <div class="subtitle">{{ $t("common.Measurements") }}</div>
          <v-row>
            <v-col v-for="(measurement, index) in props.measurements" :key="index" cols="4">
              <div>
                <strong>{{ measurement.label }}</strong
                >: {{ measurement.value }} cm
              </div>
            </v-col>
          </v-row>

          <div class="subtitle">{{ $t("common.Design_details") }}</div>
          <v-row>
            <v-col v-for="(designDetail, index) in props.designDetails" :key="index" cols="6">
              <div>
                <strong>{{ designDetail.label }}</strong
                >: {{ designDetail.value }}
              </div>
            </v-col>
          </v-row>
        </div>
      </div>

      <div ref="pdfContent">
        <slot></slot>
      </div>
    </section>
  </div>
</template>

<script setup>
import { defineProps, nextTick, ref } from "vue";
import avo from "./../userTracking/Avo";
import html2pdf from "html3pdf";

const showHeader = ref(false);
const loading = ref(false);

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  patternType: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: false,
  },
  stitchGauge: {
    type: Number,
    default: 0,
  },
  rowGauge: {
    type: Number,
    default: 0,
  },
  properties: {
    type: Array,
    required: true,
    default: () => [],
  },
  measurements: {
    type: Array,
    required: true,
    default: () => [],
  },
  designDetails: {
    type: Array,
    required: true,
    default: () => [],
  },
});

const pdfContent = ref(null);
const pdfHeader = ref(null);

async function saveAsPdf() {
  const combinedContent = document.createElement("div");
  showHeader.value = true;
  
  // Wait for the DOM to update
  nextTick(async () => {
    const header = pdfHeader.value;
    combinedContent.appendChild(header.cloneNode(true));
    showHeader.value = false;

    const content = pdfContent.value;
    combinedContent.appendChild(content.cloneNode(true));

    try {
      loading.value = true;
      const opt = {
        margin: 0.5,
        filename: `${props.title}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        documentProperties: { title: `${props.title}.pdf` },
        pagebreak: { mode: ["avoid-all"] },
      };

      await html2pdf().set(opt).from(combinedContent).save();
      avo.createPdf({ patternType: props.patternType });
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      loading.value = false;
      showHeader.value = false;
    }
  });
}
</script>

<style scoped lang="scss">
@import "./../../scss/styles.scss";

.app-pattern-toolbar {
  text-align: right;
  color: var(--knittable-lav);
  font-size: 1.5rem;
}

button {
  margin-top: 1em;
  background-color: var(--knittable-isa);
}

.pdf-header {
  text-align: left;
  padding-left: 1em;
  padding-bottom: 2.5em;
}

.pdf-header-content {
  padding-left: 10px;
}

.pdf-header h4,
.pdf-header h6 {
  color: var(--knittable-lav-darker);
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.subtitle {
  @include eyebrowsLarge;

  margin-top: 2rem;
  margin-bottom: 1rem;
  color: var(--knittable-lav);
}

.pattern-description {
  margin-bottom: 1rem;
}
</style>
